<script>
import Vue from 'vue';

export default {
  name: 'modal-merge-job',
  props: {
    onTerminated: Function,
    job: Object
  },
  data() {
    return {
      jobs: null,
      jobId: null,
      error: null
    };
  },

  computed: {
    options() {
      return {
        options: this.jobs.map(i => ({label: i.name, value: i._id}))
      };
    },
    buttons() {
      return [
        {
          label: 'Fusionner',
          action: this.mergeJob
        }
      ];
    }
  },
  methods: {
    mergeJob() {
      this.error = null;
      return Vue.backoffice.requestor.post('/jobs/merge', null, {
        from: this.job._id,
        to: this.jobId
      })
          .then(result => {
            if (result.success) {
              this.$refs.modal.close();
              if (this.onTerminated) {
                this.onTerminated(this.jobId);
              }
            } else {
              this.error = result.message;
            }
          })
          .catch(error => {
            this.error = error;
            console.log(error);
          });
    }
  },
  async created() {
    this.jobs = (await Vue.backoffice.crudRequestor.list('jobs', 0, null, {name: 1})).filter(i => i._id !== this.job._id)
  }
};
</script>

<template>
  <modal ref="modal" class="modal-merge-job" title="Fusionner un métier" :buttons="buttons">
    <p>Fusionner {{ job.name }} dans le métier</p>
    <field-select v-if="jobs" property-name="jobIntoMerge" v-model="jobId" :options="options"></field-select>
    <div v-if="error" class="error" v-html="error"/>
  </modal>
</template>

<style lang="scss">
@import "../../node_modules/@snark/backoffice/src/sass/variables.scss";

.modal-import-users {
  .modal-container {
    width: 600px;
  }

  .info {
    margin: 10px 0;
    color: #1c2e68;
  }

  .headers {
    margin-top: $gap;
  }
}
</style>
