import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';

const context = {
    businessSectors: Backoffice.populator("business_sectors", {nullable: true}, {
        displayProperty: item => {
            return item.name;
        }
    }),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if (item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            } else {
                return item.name;
            }
        }, nullable: true
    }),
    collaborators: Backoffice.populator("users", {
        filter: function (user) {
            return ((user.role === constants.UserRole.Collaborator) || (user.role === constants.UserRole.Manager));
        },
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    }),
    companies: Backoffice.populator("companies", {
        displayProperty: item => {
            return item.name;
        },
        nullable: true
    })
};

let crud = {
    name: "companiesRegistered",
    collection: "companies",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Clients enregistrés",
        "singleTitle": "Client enregistré"
    },
    emptyItem: {
        "addresses": [{
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }],
        "numbers": [],
        "contacts": [],
        "socialNetworks": {
            linkedin: '',
            twitter: '',
            facebook: ''
        },
        "real": true,
        agencyId: null
    },
    sort: {
        back: [{field: "name"}]
    },
    pagination: 50,
    backPopulateList: ["businessSector"],
    backFilter: {real: "$true"},
    backTextFilterList: true,
    // listTextFilter: ['name', 'address.city', 'agency.name'],
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "businessSector",
            "label": "Secteur d'activités",
            formatter: Backoffice.formatters.subProperty("name")
        }
    ],
    loadSingle: loadSingle,
    fields: [{
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "name",
        "label": "Nom"
    }, {
        "name": "nameSlug",
        "label": "Nom formaté",
        "readOnly": true
    }, {
        "name": "addresses",
        "label": "Adresses",
        list: true,
        children: [
            {
                name: "street",
                label: "Adresse"
            }, {
                name: "complement",
                label: "Complément"
            }, {
                name: "city",
                label: "Ville"
            }, {
                name: "citySlug",
                label: "Ville formatée",
                "readOnly": true
            }, {
                name: "zip",
                label: "Code postal"
            }, {
                name: "countryCode",
                label: "Code pays"
            }, {
                name: "department",
                label: "Département",
                "readOnly": true
            }, {
                name: "region",
                label: "Région",
                "readOnly": true
            }, {
                name: "location",
                label: "Localisation",
                list: true,
                children: [
                    {
                        "name": "_item",
                        "noLabel": true
                    }
                ],
                "readOnly": true
            }
        ]
    }, {
        "name": "businessSectorId",
        "label": "Secteur d'activités",
        formatter: Backoffice.formatters.linkObjectId("business_sectors", {
            "label": context.businessSectors.formatter()
        }),
        editor: context.businessSectors.editor()
    }, {
        "name": "description",
        "label": "Description",
        "formatters": Backoffice.formatters.multiLines(),
        "editor": {
            type: "text",
            lines: 10
        }
    }, {
        "name": "note",
        "label": "Note"
    }, {
        "name": "numbers",
        "label": "Chiffres",
        list: true,
        children: [
            {
                "name": "label",
                "label": "Label"
            },
            {
                "name": "value",
                "label": "Valeur"
            }
        ]
    }, {
        "name": "contacts",
        "label": "Contacts",
        list: true,
        children: [
            {
                "name": "role",
                "label": "Role"
            },
            {
                "name": "phone",
                "label": "Téléphone"
            },
            {
                "name": "email",
                "label": "Email"
            }
        ]
    }, {
        "name": "logo",
        "label": "Logo",
        formatter: Backoffice.formatters.picture({
            urlPrefix: process.env.VUE_APP_CDN_URL,
            maxHeight: 200
        }),
        editor: {
            type: 'image',
            preview: {
                width: 340,
                height: 256,
                urlPrefix: process.env.VUE_APP_CDN_URL
            },
            treatment: {
                width: 1024,
                height: 768,
                type: 'resize-cover',
                format: 'image/jpg',
                quality: 10
            }
        }
    }, {
        "name": "video",
        "label": "Video"
    }, {
        "name": "socialNetworks",
        "label": "Réseaux sociaux",
        children: [
            {
                "name": "linkedin",
                "label": "Linkedin"
            },
            {
                "name": "twitter",
                "label": "Twitter"
            },
            {
                "name": "facebook",
                "label": "Facebook"
            }
        ]
    }, {
        "name": "agencyId",
        "label": "Agence",
        formatter: Backoffice.formatters.linkObjectId("agencies", {
            "label": context.agencies.formatter()
        }),
        editor: context.agencies.editor()
    }, {
        "name": "collaboratorId",
        "label": "Collaborateur",
        formatter: Backoffice.formatters.linkObjectId("userAgency", {
            "label": context.collaborators.formatter()
        }),
        editor: {
            type: 'select',
            options: function (selectField) {
                const result = [];
                if (selectField && selectField.object && selectField.object.agencyId) {
                    const selectedAgencyId = selectField.object.agencyId.toString();
                    for (const collaborator of context.collaborators.items) {
                        if (collaborator.agencyId.toString() === selectedAgencyId) {
                            result.push({
                                label: collaborator.firstname + " " + collaborator.lastname,
                                value: collaborator._id
                            });
                        }
                    }
                    if (result.length === 0) {
                        result.push({label: '-', value: null});
                    }
                } else {
                    result.push({label: '-', value: null});
                }

                return Promise.resolve(result);
            },
            watch: 'agencyId'
        }
    }, {
        "name": "website",
        "label": "Site web"
    }, {
        "name": "email",
        "label": "Email"
    }, {
        "name": "phone",
        "label": "Téléphone"
    }, {
        "name": "real",
        "label": "Réelle",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        readOnly: true
    }, {
        condition: item => {
            return (!item.real);
        },
        "name": "realCompanyId",
        "label": "Client réel",
        formatter: Backoffice.formatters.linkObjectId("companiesRegistered", {
            "label": context.companies.formatter()
        }),
        editor: context.companies.editor(),
    }, {
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Mofifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }]
};
export default crud;

function completeSingle(item) {
    if (item.numbers === undefined) item.numbers = [];
    if (item.addresses === undefined) item.addresses = [];
    if (item.contacts === undefined) item.contacts = [];
    if (item.socialNetworks === undefined) {
        item.socialNetworks = {
            linkedin: '',
            twitter: '',
            facebook: ''
        }
    }
    if (item.real === undefined) item.real = true;
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.businessSectors.load(), context.agencies.load(), context.collaborators.load(), context.companies.load()]);
}
