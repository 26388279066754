import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';

const context = {
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if (item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            } else {
                return item.name;
            }
        },
        nullable: true
    })
};
let crud = {
    name: "userSupervisor",
    collection: "users",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Superviseurs",
        "singleTitle": "Superviseur"
    },
    emptyItem: {
        agencies: []
    },
    sort: {
        back: [
            {field: "lastname"}
        ]
    },
    backFilter: {
        role: constants.UserRole.Supervisor,
        state: "$ne:deleted"
    },
    backPopulateList: ["populatedAgencies"],
    backTextFilterList: true,
    // listTextFilter: ['firstname', 'lastname', 'username', 'agency.name'],
    completeSingle: completeSingle,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_URL
            }
        }
    ],
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "supervisor": "Superviseur"
            })
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "agencies",
            "label": "Agences",
            formatter: agenciesFormatter
        },
    ],
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "supervisor": "Superviseur"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "supervisor",
                        "label": "Superviseur"
                    }
                ]
            }
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "newPassword",
            "label": "Nouveau mot de passe"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "agencies",
            "label": "Agences",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("agencies", {
                        "label": context.agencies.formatter()
                    }),
                    editor: context.agencies.editor()
                },
            ],
        },
        {

            "name": "professionalFunction",
            "label": "Fonction professionnelle",
            "formatters": Backoffice.formatters.multiLines(),
            "editor": {
                type: "text",
                lines: 10
            }
        },
        {
            "name": "phone",
            "label": "Téléphone"
        }, {
            "name": "position",
            "label": "Poste"
        },
        {
            "name": "configuration",
            "label": "Configuration",
            readOnly: true
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "registered": "Inscrit"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "registered",
                        "label": "Inscrit"
                    }
                ]
            }
        },
        {
            "name": "firstConnectionToken",
            "label": "Token de première connexion",
            readOnly: true
        },
        {
            "name": "picture",
            "label": "Photo",
            formatter: Backoffice.formatters.picture({
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;

function completeSingle(item) {

    if (item.agencies === undefined) {
        item.agencies = [];
    }
    if (item.role === undefined) {
        item.role = 'supervisor';
    }
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.agencies.load()]);
}

function agenciesFormatter(field, item) {
    if(item.populatedAgencies && item.populatedAgencies.length > 0) {
        let list = [];
        for(const agency of item.populatedAgencies) {
            if(agency) {
                list.push(agency.name + ((agency.address && agency.address.city)? (" " + agency.address.city):""));
            }
        }
        return "<ul><li>" + list.join("</li><li>") + "</li></ul>";
    }
    return "-";
}

