<script>
import Vue from 'vue';

export default {
  name: 'modal-merge-certification',
  props: {
    onTerminated: Function,
    certification: Object
  },
  data() {
    return {
      certifications: null,
      certificationId: null,
      error: null
    };
  },

  computed: {
    options() {
      return {
        options: this.certifications.map(i => ({label: i.name, value: i._id}))
      };
    },
    buttons() {
      return [
        {
          label: 'Fusionner',
          action: this.mergeCertification
        }
      ];
    }
  },
  methods: {
    mergeCertification() {
      this.error = null;
      return Vue.backoffice.requestor.post('/certifications/merge', null, {
        from: this.certification._id,
        to: this.certificationId
      })
          .then(result => {
            if (result.success) {
              this.$refs.modal.close();
              if (this.onTerminated) {
                this.onTerminated(this.certificationId);
              }
            } else {
              this.error = result.message;
            }
          })
          .catch(error => {
            this.error = error;
            console.log(error);
          });
    }
  },
  async created() {
    this.certifications = (await Vue.backoffice.crudRequestor.list('certifications', 0, null, {name: 1})).filter(i => i._id !== this.certification._id)
  }
};
</script>

<template>
  <modal ref="modal" class="modal-merge-certification" title="Fusionner une certification" :buttons="buttons">
    <p>Fusionner {{ certification.name }} dans la certification</p>
    <field-select v-if="certifications" property-name="certificationIntoMerge" v-model="certificationId"
                  :options="options"></field-select>
    <div v-if="error" class="error" v-html="error"/>
  </modal>
</template>

<style lang="scss">
@import "../../node_modules/@snark/backoffice/src/sass/variables.scss";

.modal-import-users {
  .modal-container {
    width: 600px;
  }

  .info {
    margin: 10px 0;
    color: #1c2e68;
  }

  .headers {
    margin-top: $gap;
  }
}
</style>
