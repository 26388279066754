import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';

const context = {
    jobs: Backoffice.populator("jobs", {nullable: true}),
    jobOffers: Backoffice.populator("joboffers", {nullable: true}),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    users: Backoffice.populator("users", {nullable: true}, {
        backFilter: {role: constants.UserRole.Interim},
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    })
};

let crud = {
    name: "candidate",
    collection: "candidates",
    idProperty: "_id",
    section: "ATS",
    labels: {
        "listTitle": "Candidats",
        "singleTitle": "Candidat"
    },
    emptyItem: {
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        },
        status: constants.CandidateStatus.new,
        jobs: [],
        candidateLogs: [],
        timestamp: {},
        keywords: []
    },
    sort: {
        back: [
            {field: "lastname"}
        ]
    },
    pagination: 100,
    backPopulateList: ["jobOffer"],
    // backPopulateSingle: ["jobOffer"],
    listTextFilter: ['firstname', 'lastname', 'address.city'],
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "new": "Nouveau",
                "rejected": "Rejeté",
                "intergrated": "Intégré",
                "interesting": "Intéressant",
                "meeting": "À rencontrer"
            })
        },
        {
            "name": "jobOffer",
            "label": "Offre d'emploi",
            formatter: Backoffice.formatters.linkObjectId("joboffers", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        }
    ],
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "new": "Nouveau",
                "rejected": "Rejeté",
                "integrated": "Intégré",
                "interesting": "Intéressant",
                "meeting": "À rencontrer"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "new",
                        "label": "Nouveau"
                    },
                    {
                        "value": "rejected",
                        "label": "Rejeté"
                    },
                    {
                        "value": "integrated",
                        "label": "Intégré"
                    },
                    {
                        "value": "interesting",
                        "label": "Intéressant"
                    },
                    {
                        "value": "meeting",
                        "label": "À rencontrer"
                    }
                ]
            }
        },
        {
            "name": "picture",
            "label": "Photo",
            formatter: Backoffice.formatters.picture({
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            name: 'jobs',
            label: 'Métiers',
            emptyItem: [],
            children: [
                {
                    "name": "jobId",
                    "label": "Métier",
                    formatter: Backoffice.formatters.linkObjectId("jobs", {
                        "label": context.jobs.formatter()
                    }),
                    editor: context.jobs.editor()
                },
                {
                    "name": "experience",
                    "label": "Experience",
                    formatter: Backoffice.formatters.mapping({
                        "none": "Aucune",
                        "beginner": "Débutant",
                        "intermediate": "Intermédiaire",
                        "advanced": "Confirmé",
                        "expert": "Expert"
                    }),
                    editor: {
                        "type": "select",
                        "options": [
                            {
                                "value": null,
                                "label": "-"
                            },
                            {
                                "value": "none",
                                "label": "Aucune"
                            },
                            {
                                "value": "beginner",
                                "label": "Débutant"
                            },
                            {
                                "value": "intermediate",
                                "label": "Intermédiaire"
                            },
                            {
                                "value": "advanced",
                                "label": "Confirmé"
                            },
                            {
                                "value": "expert",
                                "label": "Expert"
                            }
                        ]
                    }
                },
                {
                    "name": "certifications",
                    "label": "Certifications",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("certifications", {
                                "label": context.certifications.formatter()
                            }),
                            editor: context.certifications.editor()
                        }
                    ]
                }
            ]
        },
        {
            "name": "address",
            "label": "Adresse",
            children: [
                {
                    "name": "name",
                    "label": "Nom"
                },
                {
                    "name": "street",
                    "label": "Rue"
                },
                {
                    "name": "complement",
                    "label": "Complément"
                },
                {
                    "name": "city",
                    "label": "Ville"
                },
                {
                    "name": "citySlug",
                    "label": "Ville formatée",
                    readOnly: true
                },
                {
                    "name": "zip",
                    "label": "Code postal"
                },
                {
                    "name": "countryCode",
                    "label": "Code pays",
                    "readOnly": true
                },
                {
                    name: "department",
                    label: "Département",
                    "readOnly": true
                },
                {
                    name: "region",
                    label: "Région",
                    "readOnly": true
                },
                {
                    "name": "location",
                    "label": "Localisation",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true
                        }
                    ],
                    "readOnly": true
                },
            ]
        },
        {
            "name": "cv_path",
            "label": "CV"
        },
        {
            "name": "gender",
            "label": "Civilité",
            formatter: Backoffice.formatters.mapping({
                "male": "Homme",
                "female": "Femme"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": null,
                        "label": "-"
                    },
                    {
                        "value": "male",
                        "label": "Homme"
                    },
                    {
                        "value": "female",
                        "label": "Femme"
                    }
                ]
            }
        },
        {
            "name": "birthDate",
            "label": "Date de naissance",
            formatter: Backoffice.formatters.date("DD/MM/YYYY"),
            editor: {
                type: "date"
            }
        },
        {
            "name": "birthPlace",
            "label": "Lieu de naissance"
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "jobOfferId",
            "label": "Offre d'emploi",
            formatter: Backoffice.formatters.linkObjectId("joboffers", {
                "label": context.jobOffers.formatter()
            }),
            editor: context.jobOffers.editor()
        },
        {
            "name": "sourceJobService",
            "label": "Source du service"
        },
        {
            "name": "source",
            "label": "Source"
        },
        {
            "name": "workerId",
            "label": "Intérimaire",
            formatter: Backoffice.formatters.linkObjectId("worker", {
                "label": context.users.formatter()
            }),
            editor: context.users.editor()
        },
        {
            "name": "keywords",
            "label": "Mots-clés",
            list: true,
            children:[
                {
                    "name": "_item",
                    "noLabel": true
                }
            ]
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    if (item.jobs === undefined) {
        item.jobs = [];
    }
    if (item.jobs.length > 0) {
        item.jobs = item.jobs.filter(job => job.jobId !== null)
    }
    if (item.status === undefined) {
        item.status = constants.CandidateStatus.new;
    }
    if(item.candidateLogs ===  undefined) {
        item.candidateLogs = [];
    }
    if(item.timestamp === undefined) {
        item.timestamp = {};
    }
    if(item.keywords === undefined) {
        item.keywords = [];
    }
    return Promise.resolve(item);

}

function loadSingle() {
    return Promise.all([context.jobOffers.load()/*, context.jobs.load(), context.certifications.load(), context.users.load()*/]);
}
