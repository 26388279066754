import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';
import moment from 'moment-timezone';

const context = {
    businessSectors: Backoffice.populator("business_sectors", {nullable: true}),
    companies: Backoffice.populator("companies", {
        displayProperty: item => {
            return item.name;
        },
        nullable: true
    }),
    jobs: Backoffice.populator("jobs", {nullable: true}),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    users: Backoffice.populator("users", {
        filter: function (user) {
            return ((user.role === constants.UserRole.Collaborator) || (user.role === constants.UserRole.Manager));
        },
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    }),
    unavailabilities: Backoffice.populator("unavailabilities", {
        displayProperty: item => {
            return moment(item.date).format('DD/MM/YYYY');
        },
        nullable: true
    })
};
let crud = {
    name: "userCompany",
    collection: "users",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Collaborateurs (entreprise)",
        "singleTitle": "Collaborateur (entreprise)"
    },
    emptyItem: {
        businessSectors: [],
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        },
        status: constants.userStatus.registered,
        role: 'company',
        notifications: {
            company_mission_extended: ['email'],
            company_mission_to_evaluate: ['email'],
            mission_agency_declined: ['email'],
            mission_completed_company: ['email'],
            mission_propose_extension: ['email'],
            mission_without_agency_close_to_begin: ['email'],
            mission_workers_arriving_tomorrow: ['email'],
            worker_presented: ['email']
        }
    },
    pagination: 100,
    sort: {
        back: [
            {field: "lastname"}]
    },
    backFilter: {
        role: constants.UserRole.Company,
        state: "$ne:deleted"
    },
    backPopulateList: ["company"],
    // filterList: context.companies.completeList("companyId", "company"),
    backTextFilterList: true,
    // listTextFilter: ['firstname', 'lastname', 'username', 'address.city', 'company.name'],
    completeSingle: completeSingle,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_URL
            }
        }
    ],
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "company": "Client"
            })
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "company",
            "label": "Client",
            formatter: Backoffice.formatters.linkObjectId("companies", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        }],
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "company": "Client"
            }),
            readOnly: true
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "newPassword",
            "label": "Nouveau mot de passe"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        }, {
            "name": "position",
            "label": "Poste"
        },
        {
            "name": "companyId",
            "label": "Client",
            formatter: Backoffice.formatters.linkObjectId("companies", {
                "label": context.companies.formatter()
            }),
            editor: context.companies.editor(),
        },
        {
            "name": "businessSectors",
            "label": "Secteur d'activités",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("business_sectors", {
                        "label": context.businessSectors.formatter()
                    }),
                    editor: context.businessSectors.editor()
                }]

        },
        {
            "name": "configuration",
            "label": "Configuration",
            readOnly: true
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "invited": "Invité",
                "reinvited": "Relancé",
                "registered": "Inscrit",
                "imported": "Importé",
                "no_longer_registered": "Plus inscrit"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "invited",
                        "label": "Invité"
                    },
                    {
                        "value": "registered",
                        "label": "Inscrit"
                    },
                    {
                        "value": "imported",
                        "label": "Importé"
                    },
                    {
                        "value": "no_longer_registered",
                        "label": "Plus inscrit"
                    }]
            }
        },
        {
            "name": "firstConnectionToken",
            "label": "Token de première connexion",
            readOnly: true
        },
        {
            "name": "picture",
            "label": "Photo",
            formatter: Backoffice.formatters.picture({
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            "name": "notifications",
            "label": "Notifications",
            children: [
                {
                    "name": "company_mission_to_evaluate",
                    "label": "Mission à évaluer",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                },
                {
                    "name": "mission_agency_declined",
                    "label": "Mission déclinée par l'agence",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                }, {
                    "name": "mission_completed_company",
                    "label": "Mission complète",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                },
                {
                    "name": "mission_propose_extension",
                    "label": "Proposition d'extension de mission",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                },
                {
                    "name": "mission_without_agency_close_to_begin",
                    "label": "Mission proche sans agence",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                },
                {
                    "name": "mission_workers_arriving_tomorrow",
                    "label": "Mission dont les intérimaires arrivent demain",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                },
                {
                    "name": "worker_presented",
                    "label": "Intérimaire présenté",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }]
                            }
                        }]
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }]
};
export default crud;

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    if (item.status === undefined) {
        item.status = constants.userStatus.registered;
    }
    if (item.businessSectors === undefined) {
        item.businessSectors = [];
    }
    if (item.role === undefined) {
        item.role = 'company';
    }
    if (item.notifications === undefined) {
        item.notifications = {
            company_mission_extended: ['email'],
            company_mission_to_evaluate: ['email'],
            mission_agency_declined: ['email'],
            mission_completed_company: ['email'],
            mission_propose_extension: ['email'],
            mission_without_agency_close_to_begin: ['email'],
            mission_workers_arriving_tomorrow: ['email'],
            worker_presented: ['email']
        }
    }
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.companies.load(), context.businessSectors.load()]);
//     return Promise.all([context.businessSectors.load(), context.jobs.load(), context.certifications.load(), context.users.load(), context.unavailabilities.load(), context.companies.load()]);
}