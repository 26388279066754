import Vue from 'vue';


export default {
    list: list,
    read: read,
    update: update,
    delete: remove,

    crud: getCRUD
};

// -------------------------------------------------------------------------
// CRUD
// -------------------------------------------------------------------------

function getCRUD() {
    return {
        list: list(),
        single: read(),
        update: update(),
        delete: remove()
    };
}

function list() {
    return (function () {
        return function () {
            return Vue.http.get(getRemoteAPIUrl("/users/duplicates"))
                .then(response => {
                    // success
                    if (response.ok) {
                        let result = response.body;
                        if (result.success) {
                            if (result.data && result.data.items) {
                                console.log("Found " + result.data.items.length + " workersDuplicates items.");
                                return Promise.resolve(result.data.items);
                            } else {
                                console.log("No data", result);
                                return Promise.reject("No workerDuplivates items for now.");
                            }
                        } else {
                            console.log("Error: ", result);
                            return Promise.reject(result.error);
                        }
                    } else {
                        console.log("RESPONSE NOT OK: ", response.status, response.statusText, response.text);
                        return Promise.reject(response.statusText);
                    }
                }, response => {
                    console.log(response);
                    const text = "Un problème est survenu, veuillez vérifier votre accès internet<br/>(" + (response.statusText ? response.statusText : "erreur inconnue") + ").";
                    return Promise.reject(text);
                });
        }
    })();
}

function read() {
    return (function () {
        return function (itemId) {
            console.log('ITEMID : ' + itemId)
            return Vue.http.get(getRemoteAPIUrl("/crud/read/users/" + itemId))
                .then(response => {
                    // success
                    if (response.ok) {
                        let result = response.body;
                        if (result.success) {
                            console.log("RESULT: ", result);
                            if (result.item) {
                                console.log("Found item: ", result.item);
                                return Promise.resolve(result.item);
                            } else {
                                console.log("No data", result);
                                return Promise.reject("users item not found.");
                            }
                        } else {
                            console.log("Error: ", result);
                            return Promise.reject(result.error);
                        }
                    } else {
                        console.log("RESPONSE NOT OK: ", response.status, response.statusText, response.text);
                        return Promise.reject(response.statusText);
                    }
                }, response => {
                    console.log(response);
                    const text = "Un problème est survenu, veuillez vérifier votre accès internet<br/>(" + (response.statusText ? response.statusText : "erreur inconnue") + ").";
                    return Promise.reject(text);
                });
        }
    })();
}

function update() {
    return (function () {
        return function (itemId, object) {
            object.collection = "users";
            object.id = itemId;
            return Vue.http.put(getRemoteAPIUrl("/crud/update/users/" + itemId), object)
                .then(response => {
                    // success
                    if (response.ok) {
                        let result = response.body;
                        if (result.success) {
                            console.log("RESULT: ", result);
                            if (result.item) {
                                console.log("Item updated: ", result.item);
                                return Promise.resolve(result.item);
                            } else {
                                console.log("No data", result);
                                return Promise.reject("users item not found.");
                            }
                        } else {
                            console.log("Error: ", result);
                            return Promise.reject(result.message);
                        }
                    } else {
                        console.log("RESPONSE NOT OK: ", response.status, response.statusText, response.text);
                        return Promise.reject(response.statusText);
                    }
                }, response => {
                    console.log(response);
                    const text = "Un problème est survenu, veuillez vérifier votre accès internet<br/>(" + (response.statusText ? response.statusText : "erreur inconnue") + ").";
                    return Promise.reject(text);
                });
        }
    })();
}

function remove() {
    return (function () {
        return function (itemId) {
            return Vue.http.delete(getRemoteAPIUrl("/crud/delete/users/" + itemId))
                .then(response => {
                    // success
                    if (response.ok) {
                        let result = response.body;
                        if (result.success) {
                            console.log("RESULT: ", result);
                            return Promise.resolve(true);
                        } else {
                            console.log("Error: ", result);
                            return Promise.reject(result.message);
                        }
                    } else {
                        console.log("RESPONSE NOT OK: ", response.status, response.statusText, response.text);
                        return Promise.reject(response.statusText);
                    }
                }, response => {
                    console.log(response);
                    const text = "Un problème est survenu, veuillez vérifier votre accès internet<br/>(" + (response.statusText ? response.statusText : "erreur inconnue") + ").";
                    return Promise.reject(text);
                });
        }
    })();
}


// -------------------------------------------------------------------------
// PRIVATE
// -------------------------------------------------------------------------

function getRemoteAPIUrl(url) {
    // console.log("PROCESS ENV: ", process.env);
    return process.env.VUE_APP_API_URL + url;
}