import Backoffice from "@snark/backoffice";

let crud = {
    name: "message_templates",
    idProperty: "_id",
    section: "ATS",
    labels: {
        "listTitle": "Modèles de messages",
        "singleTitle": "Modèle de message"
    },
    emptyItem: {},
    sort: {
        back: [{field: "name"}]
    },
    listTextFilter: ['name'],
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        }, {
            "name": "text",
            "label": "Texte"
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "name",
            "label": "Nom"
        }, {
            "name": "nameSlug",
            "label": "Nom formaté",
            "readOnly": true
        },
        {
            "name": "text",
            "label": "Texte"
        },
        {
            "name": "language",
            "label": "Langue",
            "readOnly": true
        },
        {
            "name": "type",
            "label": "Type",
            formatter: Backoffice.formatters.mapping({
                "ats_agency_message": "Message",
                "ats_agency_rejection": "Refus",
                "ats_agency_interview": "Entretien"
            }),
            editor: {
                type: "select",
                options: [{
                    "value": "ats_agency_message",
                    "label": "Message"
                }, {
                    "value": "ats_agency_rejection",
                    "label": "Refus"
                }, {
                    "value": "ats_agency_interview",
                    "label": "Entretien"
                }],
                sort: "none"
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;
