import Backoffice from "@snark/backoffice";
import ModalMergeJob from '@/components/ModalMergeJob';
import axios from 'axios';

const context = {
    businessSectors: Backoffice.populator("business_sectors")
};

let crud = {
    name: "jobs",
    idProperty: "_id",
    section: "data",
    labels: {
        "listTitle": "Métiers",
        "singleTitle": "Métier"
    },
    emptyItem: {
        "synonims": [],
        "slugs": [],
        "verified": false
    },
    sort: {
        back: [{field: "name"}]
    },
    pagination: 100,
    backTextFilterList: true,
    // listTextFilter: ['name'],
    // listSelectFilter: [{label: 'Tous', value: '-'}, {label: 'Non vérifié', value: false}, {
    //     label: 'Vérifié',
    //     value: true
    // }],
    // listSelectFilterValue: '-',
    // listSelectFilterFunction: (item, selectValue) => {
    //     if (selectValue !== '-') {
    //         if ((item.verified === undefined) && (selectValue === false)) {
    //             item.verified = selectValue;
    //             return item.verified === false;
    //         } else {
    //             return item.verified === selectValue;
    //         }
    //     } else {
    //         return item;
    //     }
    // },
    completeSingle: completeSingle,
    loadSingle: loadSingle,
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "code",
            "label": "Code"
        },
        {
            "name": "PCS",
            "label": "PCS",
        },
        {
            "name": "verified",
            "label": "Vérifié",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"])
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "Code",
            "label": "Code"
        },
        {
            "name": "PCS",
            "label": "PCS"
        },
        {
            "name": "verified",
            "label": "Vérifié",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "synonims",
            "label": "Synonymes",
            list: true,
            children: [{
                "name": "_item",
                "noLabel": true
            }],
            listEmptyItem: []
        },
        {
            "name": "slugs",
            "label": "Noms formatés",
            "readOnly": true,
            list: true,
            children: [{
                "name": "_item",
                "noLabel": true,

            }],
            listEmptyItem: []
        },
        {
            "name": "businessSectors",
            "label": "Secteurs d'activité",
            children: [
                {
                    formatter: context.businessSectors.formatter(),
                    editor: context.businessSectors.editor(),
                    "name": "_item",
                    "noLabel": true
                }
            ],
            list: true
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    viewActions: [
        {
            label: "Fusionner ce métier avec un autre",
            action: mergeJob
        },
        {
            label: "Lancer la synchronisation des jobs Evolia",
            action: async function (item) {
                try {
                    let result = await axios.get(process.env.VUE_APP_SYNCHRO_URL + '/alljobssynchro/0');
                    console.log("RESULT: ", result);
                    alert("Synchronisation lancée");
                    return Promise.resolve({message: "La synchronisation est lancée"});
                }
                catch (err) {
                    console.log("ERROR: ", err);
                    return Promise.reject("Impossible de lancer la synchronisation.");
                }
            }
        },
        {
            label: "Lancer la migration des jobs Evolia",
            action: async function (item) {
                try {
                    let result = await axios.get(process.env.VUE_APP_SYNCHRO_URL + '/alljobssynchro/1');
                    console.log("RESULT: ", result);
                    alert("migration jobs lancée");
                    return Promise.resolve({message: "La migration est lancée"});
                }
                catch (err) {
                    console.log("ERROR: ", err);
                    return Promise.reject("Impossible de lancer la migration.");
                }
            }
        },
        {
            label: "Obtenir le status de la dernière synchronisation des jobs Evolia",
            action: async function (item) {
                try {
                    let result = await axios.get(process.env.VUE_APP_SYNCHRO_URL + '/alljobssynchroStatus');
                    console.log("RESULT: ", result);
                    alert("Synchronisation jobs lancée");
                    return Promise.resolve({message: "Le status de la dernière synchronisation des jobs Evolia est retournée."});
                }
                catch (err) {
                    console.log("ERROR: ", err);
                    return Promise.reject("Impossible de lancer le get de status de synchronisation de Jobs Evolia.");
                }
            }
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.synonims === undefined) {
        item.synonims = [];
    }
    if (item.slugs === undefined) {
        item.slugs = [];
    }
    if (item.verified === undefined) {
        item.verified = false;
    }
    return Promise.resolve(item);
}

function mergeJob(job) {
    return new Promise((resolve, _reject) => {
        this.appendComponent(ModalMergeJob, this, {
            job: job,
            onTerminated: () => {
                this.$router.push({name: 'jobsList', params: {}});
                resolve();
            }
        });
    });
}

function loadSingle() {
    return Promise.all([context.businessSectors.load()]);
}