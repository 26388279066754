import api from '../helpers/apiWorkersDuplicates';
import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';
import ModalMergeWorker from "@/components/ModalMergeWorker";

const context = {
    businessSectors: Backoffice.populator("business_sectors", {nullable: true}),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if(item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            }
            else {
                return item.name;
            }
        },
        nullable: true
    }),
    companies: Backoffice.populator("companies", {
        displayProperty: item => {
            return item.name;
        },
        nullable: true
    }),
    jobs: Backoffice.populator("jobs"),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    users: Backoffice.populator("users", {nullable: true}, {
        filter: function (user) {
            return ((user.role === constants.UserRole.Collaborator) || (user.role === constants.UserRole.Manager));
        },
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    })
};
let crud = {
    name: "workerDuplicates",
    idProperty: "_id",
    api: api.crud(),
    section: "base",
    labels: {
        "listTitle": "Intérimaires ayant un même email et/ou un même téléphone",
        "singleTitle": "Intérimaire ayant un même email et/ou un même téléphone"
    },
    emptyItem: {
        businessSectors: [],
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        },
        status: constants.userStatus.registered,
        role: 'interim',
        agencies: [],
        notifications: {
            confirmed_mission_to_worker: ['email', 'sms'],
            mission_canceled: ['email', 'sms'],
            mission_change_of_address: ['email', 'sms'],
            mission_change_of_periods: ['email', 'sms'],
            mission_waiting_for_worker_answer: ['email', 'sms'],
            not_confirmed_mission_to_worker: ['email', 'sms'],
            proposed_mission_to_worker: ['email', 'sms'],
            rejected_mission_to_worker: ['email', 'sms'],
            worker_missed_mission: ['email', 'sms'],
            worker_mission_extended: ['email', 'sms'],
            worker_mission_to_evaluate: ['email', 'sms'],
            worker_update_agenda_reminder: ['email', 'sms']
        }
    },
    sort: {
        back: [
            {field: "lastname"}]
    },
    backFilter: {
        role: constants.UserRole.Interim,
        state: "$ne:deleted",
        agencies: "$size:0"
    },
    filterList: context.agencies.completeList("agencyId", "agency"),
    listTextFilter: ['firstname', 'lastname', 'username', 'address.city', 'agency.name'],
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "interim": "Intérimaire"
            })
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "agencies",
            "label": "Agences",
            formatter: function (field, item) {
                if (!item.agencies) {
                    return null;
                }
                return item.agencies.map(i => {
                    const agence = context.agencies.find(i)
                    return agence && agence.name + ' ' + agence.address.city || null;
                }).join("<br>")
            }
        }],
    loadList: loadList,
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "interim": "Intérimaire"
            }),
            readOnly: true
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "newPassword",
            "label": "Nouveau mot de passe"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        },
        {
            "name": "agencies",
            "label": "Agences",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("agencies", {
                        "label": context.agencies.formatter()
                    }),
                    editor: context.agencies.editor()
                },
            ],
        },
        {
            "name": "businessSectors",
            "label": "Secteur d'activités",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("business_sectors", {
                        "label": context.businessSectors.formatter()
                    }),
                    editor: context.businessSectors.editor()
                }
            ]

        },
        {
            "name": "configuration",
            "label": "Configuration",
            readOnly: true
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "invited": "Invité",
                "reinvited": "Relancé",
                "registered": "Inscrit",
                "imported": "Importé",
                "no_longer_registered": "Plus inscrit"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "invited",
                        "label": "Invité"
                    },
                    {
                        "value": "registered",
                        "label": "Inscrit"
                    },
                    {
                        "value": "imported",
                        "label": "Importé"
                    },
                    {
                        "value": "no_longer_registered",
                        "label": "Plus inscrit"
                    }
                ]
            }
        },
        {
            "name": "firstConnectionToken",
            "label": "Token de première connexion",
            readOnly: true
        },
        {
            "name": "picture",
            "label": "Photo",
            formatter: Backoffice.formatters.picture({
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            "name": "notifications",
            "label": "Notifications",
            children: [
                {
                    "name": "confirmed_mission_to_worker",
                    "label": "Mission confirmée à un intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "mission_canceled",
                    "label": "Mission annulée",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "mission_change_of_address",
                    "label": "Changement d'adresse de la mission",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "mission_change_of_periods",
                    "label": "Changement de périodes de la mission",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "mission_waiting_for_worker_answer",
                    "label": "Mission en attente de la réponse de l'intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "not_confirmed_mission_to_worker",
                    "label": "Mission non confirmée à un intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "proposed_mission_to_worker",
                    "label": "Mission proposée à un intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "rejected_mission_to_worker",
                    "label": "Mission refusée à un intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "welcome_worker",
                    "label": "Bienvenue à l'intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_missed_mission",
                    "label": "L'intérimaire a manqué une mission",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_mission_extended",
                    "label": "Mission prolongée à l'intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_mission_to_evaluate",
                    "label": "L'intérimaire doit évaluer la mission",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_update_agenda_reminder",
                    "label": "L'intérimaire doit mettre à jour son agenda",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email",
                                "sms": "SMS"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    },
                                    {
                                        "value": "sms",
                                        "label": "SMS"
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'jobs',
            label: 'Métiers',
            emptyItem: [],
            children: [
                {
                    "name": "jobId",
                    "label": "Métier",
                    formatter: Backoffice.formatters.linkObjectId("jobs", {
                        "label": context.jobs.formatter()
                    }),
                    editor: context.jobs.editor()
                },
                {
                    "name": "experience",
                    "label": "Experience",
                    formatter: Backoffice.formatters.mapping({
                        "none": "Aucune",
                        "beginner": "Débutant",
                        "intermediate": "Intermédiaire",
                        "advanced": "Confirmé",
                        "expert": "Expert"
                    }),
                    editor: {
                        "type": "select",
                        "options": [
                            {
                                "value": null,
                                "label": "-"
                            },
                            {
                                "value": "none",
                                "label": "Aucune"
                            },
                            {
                                "value": "beginner",
                                "label": "Débutant"
                            },
                            {
                                "value": "intermediate",
                                "label": "Intermédiaire"
                            },
                            {
                                "value": "advanced",
                                "label": "Confirmé"
                            },
                            {
                                "value": "expert",
                                "label": "Expert"
                            }
                        ]
                    }
                },
                {
                    "name": "certifications",
                    "label": "Certifications",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("certifications", {
                                "label": context.certifications.formatter()
                            }),
                            editor: context.certifications.editor()
                        }
                    ]
                }
            ]
        },
        {
            "name": "managers",
            "label": "Responsables",
            formatter: managerFormatter,
            readOnly: true
        },
        {
            "name": "regularAvailabilities",
            "label": "Disponibilités régulières",
            list: true,
            children: [
                {
                    name: "morning",
                    label: "Matin",
                    formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                    editor: {
                        "type": "boolean",
                        "labels": ["Non", "Oui"]
                    }
                },
                {
                    name: "afternoon",
                    label: "Après-midi",
                    formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                    editor: {
                        "type": "boolean",
                        "labels": ["Non", "Oui"]
                    }
                },
                {
                    name: "evening",
                    label: "Soir",
                    formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                    editor: {
                        "type": "boolean",
                        "labels": ["Non", "Oui"]
                    }
                },
                {
                    name: "night",
                    label: "Nuit",
                    formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                    editor: {
                        "type": "boolean",
                        "labels": ["Non", "Oui"]
                    }
                }
            ],
            emptyItem: [
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                }
            ]
        },
        {
            "name": "nightShift",
            "label": "Travail de nuit",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "weekendShift",
            "label": "Travail le week-end",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "vehicle",
            "label": "Possède un véhicule",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "address",
            "label": "Adresse",
            children: [
                {
                    "name": "name",
                    "label": "Nom"
                },
                {
                    "name": "street",
                    "label": "Rue"
                },
                {
                    "name": "complement",
                    "label": "Complément"
                },
                {
                    "name": "city",
                    "label": "Ville"
                },
                {
                    "name": "citySlug",
                    "label": "Ville formatée",
                    readOnly: true
                },
                {
                    "name": "zip",
                    "label": "Code postal"
                },
                {
                    "name": "countryCode",
                    "label": "Code pays",
                    "readOnly": true
                },
                {
                    name: "department",
                    label: "Département",
                    "readOnly": true
                },
                {
                    name: "region",
                    label: "Région",
                    "readOnly": true
                },
                {
                    "name": "location",
                    "label": "Localisation",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true
                        }
                    ],
                    "readOnly": true
                },
            ]
        },
        {
            "name": "cv_path",
            "label": "CV"
        },
        {
            "name": "publicResume",
            "label": "CV Public"
        },
        {
            "name": "state",
            "label": "État",
            readOnly: true
        },
        {
            "name": "maxTravelDistance",
            "label": "Distance maximale"
        },
        {
            "name": "maxTravelTime",
            "label": "Temps de trajet maximal"
        },
        {
            "name": "carPooling",
            "label": "Covoiturage",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "gender",
            "label": "Civilité",
            formatter: Backoffice.formatters.mapping({
                "male": "Homme",
                "female": "Femme"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": null,
                        "label": "-"
                    },
                    {
                        "value": "male",
                        "label": "Homme"
                    },
                    {
                        "value": "female",
                        "label": "Femme"
                    }
                ]
            }
        },
        {
            "name": "birthDate",
            "label": "Date de naissance",
            formatter: Backoffice.formatters.date("DD/MM/YYYY"),
            editor: {
                type: "date"
            }
        },
        {
            "name": "birthPlace",
            "label": "Lieu de naissance"
        },
        {
            "name": "notes",
            "label": "Notes"
        }, {
            "name": "presentationText",
            "label": "Texte de présentation"
        }, {
            "name": "blacklist",
            "label": "Liste noire",
            list: true,
            children: [
                {
                    "name": "from",
                    "label": "De la part ",
                    formatter: Backoffice.formatters.mapping({
                        "company": "du client",
                        "worker": "de l'intérimaire",
                        "both": "des deux"
                    })
                },
                {
                    "name": "companyId",
                    "label": "Client",
                    formatter: Backoffice.formatters.linkObjectId("companies", {
                        "label": context.companies.formatter()
                    }),
                },
            ],
            readOnly: true
        },
        {
            "name": "expectedSalary",
            "label": "Salaire attendu"
        }, {
            "name": "inactiveByAgencies",
            "label": "Inactif par agences",
            list: true,
            readOnly: true
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    viewActions: [
        {
            label: "Fusionner cet intérimaire avec un autre",
            action: mergeWorker
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    if (item.unavailabilities === undefined) {
        item.unavailabilities = [];
    }
    if (item.jobs === undefined) {
        item.jobs = [];
    }
    if (item.jobs.length > 0) {
        item.jobs = item.jobs.filter(job => job.jobId !== null)
    }
    if (item.regularAvailabilities === undefined) {
        item.regularAvailabilities = [
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            },
            {
                morning: true,
                afternoon: true,
                evening: true,
                night: true
            }]
    }
    if (item.status === undefined) {
        item.status = constants.userStatus.registered;
    }
    if (item.businessSectors === undefined) {
        item.businessSectors = [];
    }
    if (item.agencies === undefined) {
        item.agencies = [];
    }
    if (item.role === undefined) {
        item.role = 'interim';
    }
    if (item.managers === undefined) {
        item.managers = {};
    }
    if (item.notifications === undefined) {
        item.notifications = {
            confirmed_mission_to_worker: ['email', 'sms'],
            mission_canceled: ['email', 'sms'],
            mission_change_of_address: ['email', 'sms'],
            mission_change_of_periods: ['email', 'sms'],
            mission_waiting_for_worker_answer: ['email', 'sms'],
            not_confirmed_mission_to_worker: ['email', 'sms'],
            proposed_mission_to_worker: ['email', 'sms'],
            rejected_mission_to_worker: ['email', 'sms'],
            worker_missed_mission: ['email', 'sms'],
            worker_mission_extended: ['email', 'sms'],
            worker_mission_to_evaluate: ['email', 'sms'],
            worker_update_agenda_reminder: ['email', 'sms']
        }
    }
    return Promise.resolve(item);
}

function loadList() {
    return Promise.all([context.agencies.load()]);
}

function loadSingle() {
    return Promise.all([context.businessSectors.load(), context.agencies.load(), context.jobs.load(), context.certifications.load(), context.users.load(), context.companies.load()]);
}

function managerFormatter(field, item) {
    let managers = [];
    if (Object.keys(item.managers).length > 0) {
        Object.keys(item.managers).forEach(agencyId => {
            const agency = context.agencies.find(agencyId);
            const manager = context.users.find(item.managers[agencyId]);
            managers.push(agency.name + ' : ' + manager.firstname + ' ' + manager.lastname);
        })
    }
    return managers;
}

function mergeWorker(worker) {
    return new Promise((resolve, _reject) => {
        this.appendComponent(ModalMergeWorker, this, {
            worker: worker,
            onTerminated: (workerId) => {
                this.$router.push({path: `/worker/view/${workerId}`});
                resolve();
            }
        });
    });
}