import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';

const context = {
    companies: Backoffice.populator("companies", {nullable: true}),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if(item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            }
            else {
                return item.name;
            }
        },
        nullable: true
    }),
    users: Backoffice.populator("users", {
        backFilter: {role: "$in:" + [constants.UserRole.Collaborator, constants.UserRole.Manager].join(",")},
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    }),
    jobs: Backoffice.populator("jobs", {nullable: true}),
    missions: Backoffice.populator("missions", {nullable: true}),
};

let crud = {
    name: "joboffers",
    collection: "joboffers",
    idProperty: "_id",
    section: "ATS",
    labels: {
        "listTitle": "Campagnes",
        "singleTitle": "Campagne"
    },
    emptyItem: {
        "services": {},
        "certifications": []
    },
    pagination: 100,
    backTextFilterList: true,
    // listTextFilter: ['name'],
    backPopulateList: ["agency", "owner", "client"],
    sort:{back:{field: "start", order: "desc"}},
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "agency",
            "label": "Agence",
            formatter: Backoffice.formatters.linkObjectId("agencies", {
                idProperty: "_id",
                "label": Backoffice.formatters.concat(Backoffice.formatters.subProperty("name"), ' ', Backoffice.formatters.subProperty("address.city"))
            })
        },
        {
            "name": "owner",
            "label": "Responsable",
            formatter: Backoffice.formatters.linkObjectId("userAgency", {
                idProperty: "_id",
                "label": Backoffice.formatters.fullName()
            })
        },
        {
            "name": "client",
            "label": "Client",
            formatter: Backoffice.formatters.linkObjectId("companies", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            })
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "draft": "En projet",
                "archived": "Archivée",
                "canceled": "Annulée",
                "filled": "Complétée",
                "old": "Ancienne",
                "posted": "Postée"
            })
        }
    ],
    loadList: loadList,
    loadSingle: loadSingle,
    fields: [{
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "name",
        "label": "Nom"
    }, {
        "name": "nameSlug",
        "label": "Nom formaté"
    }, {
        "name": "agencyId",
        "label": "Agence",
        formatter: Backoffice.formatters.linkObjectId("agencies", {
            "label": context.agencies.formatter()
        }),
        editor: context.agencies.editor()
    }, {
        "name": "ownerId",
        "label": "Responsable agence",
        formatter: Backoffice.formatters.linkObjectId("userAgency", {
            "label": context.users.formatter()
        }),
        editor: context.users.editor()
    },  {
        "name": "clientId",
        "label": "Client",
        formatter: Backoffice.formatters.linkObjectId("companies", {
            "label": context.companies.formatter()
        }),
        editor: context.companies.editor()
    }, {
        "name": "status",
        "label": "Statut",
        formatter: Backoffice.formatters.mapping({
            "draft": "En projet",
            "archived": "Archivée",
            "canceled": "Annulée",
            "filled": "Complétée",
            "old": "Ancienne",
            "posted": "Postée"
        }),
        editor: {
            "type": "select",
            "options": [
                {
                    "value": "draft",
                    "label": "En projet"
                },
                {
                    "value": "archived",
                    "label": "Archivé"
                },
                {
                    "value": "canceled",
                    "label": "Annulé"
                },
                {
                    "value": "filled",
                    "label": "Complétée"
                },
                {
                    "value": "old",
                    "label": "Ancienne"
                },
                {
                    "value": "posted",
                    "label": "Postée"
                }
            ]
        }
    }, {
        "name": "location",
        "label": "Ville"
    }, {
        "name": "description",
        "label": "Description",
        "formatters": Backoffice.formatters.multiLines(),
        "editor": {
            type: "text",
            lines: 10
        }
    }, {
        "name": "services",
        "label": "Services",
        list: true,
        readOnly: true
    }, {
        "name": "start",
        "label": "Début",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "end",
        "label": "Fin",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
            "name": "jobId",
            "label": "Métier",
            formatter: Backoffice.formatters.linkObjectId("jobs", {
                "label": context.jobs.formatter()
            }),
            editor: context.jobs.editor()
    }, {
            "name": "certifications",
            "label": "Certifications",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("certifications", {
                        "label": context.certifications.formatter()
                    }),
                    editor: context.certifications.editor()
                }
            ]
    },{
        "name": "experience",
        "label": "Expérience"
    },{
        "name": "missionId",
        "label": "Mission",
        formatter: Backoffice.formatters.linkObjectId("missions", {
            "label": context.missions.formatter()
        }),
        editor: context.missions.editor()
    },{
        "name": "missionStartDate",
        "label": "Début de la mission",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    },{
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Mofifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }]
};
export default crud;

function completeSingle(item) {
    if (item.services === undefined) item.services = {};
    if (item.certifications === undefined) item.certifications = [];
    return Promise.resolve(item);
}

function loadList() {
    return Promise.all([context.companies.load(), context.agencies.load(), context.users.load()]);
}

function loadSingle() {
    return Promise.all([context.companies.load(), context.agencies.load(), context.users.load(), context.certifications.load(), context.jobs.load(), context.missions.load()]);
}