import Backoffice from "@snark/backoffice";
import * as constants from "../helpers/constants";

const context = {
    users: Backoffice.populator("users", {
        backFilter: {role: "$in:" + [constants.UserRole.Collaborator, constants.UserRole.Manager].join(",")},
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    }),
    candidates: Backoffice.populator("candidates", {
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    })
};

let crud = {
    name: "interviews",
    idProperty: "_id",
    section: "ATS",
    labels: {
        "listTitle": "Entretiens",
        "singleTitle": "Entretien"
    },
    emptyItem: {},
    pagination: 100,
    sort: {
        back: [{field: "date"}]
    },
    listTextFilter: [],
    completeSingle: completeSingle,
    loadList: loadList,
    loadSingle: loadSingle,
    listColumns: [
        {
            "name": "date",
            "label": "Date"
        }, {
            "name": "candidateId",
            "label": "Candidat",
            formatter: Backoffice.formatters.linkObjectId("candidates", {
                "label": context.candidates.formatter()
            })
        },
        {
            "name": "collaboratorId",
            "label": "Collaborateur",
            formatter: Backoffice.formatters.linkObjectId("users", {
                "label": context.users.formatter()
            })
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "date",
            "label": "Date"
        }, {
            "name": "candidateId",
            "label": "Candidat",
            formatter: Backoffice.formatters.linkObjectId("candidates", {
                "label": context.candidates.formatter()
            }),
            editor: context.candidates.editor()
        },
        {
            "name": "collaboratorId",
            "label": "Collaborateur",
            formatter: Backoffice.formatters.linkObjectId("users", {
                "label": context.users.formatter()
            }),
            editor: context.users.editor()
        },
        {
            "name": "candidateMessage",
            "label": "Message du candidat"
        },
        {
            "name": "collaboratorMessage",
            "label": "Message du collaborateur"
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;

function loadList() {
    return Promise.all([context.users.load(), context.candidates.load()]);
}


function loadSingle() {
    return Promise.all([context.users.load(), context.candidates.load()]);
}

function completeSingle(item) {
    return Promise.resolve(item);

}