import Backoffice from '@snark/backoffice';

let crud = {
    name: 'business_sectors',
    section: 'data',
    idProperty: '_id',
    labels: {
        listTitle: 'Secteurs d\'activités',
        singleTitle: 'Secteur d\'activités'
    },

    // --- list
    sort: {
        back: [{field: "name"}]
    },
    listTextFilter: ['name'],
    completeSingle: completeSingle,
    listColumns: [
        {
            name: 'name',
            label: 'Nom'
        }
    ],

    // --- single
    fields: [
        {
            name: 'name',
            label: 'Nom'
        },
        {
            name: 'nameSlug',
            label: 'Nom formaté',
            "readOnly": true
        },
        {
            "name": "pictogram",
            "label": "Pictogramme",
            formatter: Backoffice.formatters.picture({
                urlPrefix: process.env.VUE_APP_CDN_URL,
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256,
                    urlPrefix: process.env.VUE_APP_CDN_URL
                },
            }
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            formatter: Backoffice.formatters.date('DD/MM/YYYY'),
            "readOnly": true
        },
        {
            name: 'updatedAt',
            label: 'Mis à jour le',
            formatter: Backoffice.formatters.date('DD/MM/YYYY'),
            "readOnly": true
        }
    ],
    emptyItem: {}
};

export default crud;

function completeSingle(item) {
    return Promise.resolve(item);
}
