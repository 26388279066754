import Backoffice from "@snark/backoffice";
import * as constants from "@/helpers/constants";

const context = {
    companies: Backoffice.populator("companies", {nullable: true}),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if(item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            }
            else {
                return item.name;
            }
        },
        nullable: true
    }),
    users: Backoffice.populator("users", {
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    }),
    jobs: Backoffice.populator("jobs", {nullable: true})
};

let crud = {
    name: "missions",
    collection: "missions",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Missions",
        "singleTitle": "Missions"
    },
    emptyItem: {
        "periods": [],
        "positions": [],
        "workers": [],
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        },
    },
    pagination: 100,
    backTextFilterList: true,
    // listTextFilter: ['name'],
    backPopulateList: ["agency", "client", "owner"],
    sort: {back: [{field: "startAt", order: "desc"}]},
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "agency",
            "label": "Agence",
            formatter: Backoffice.formatters.linkObjectId("agencies", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        },
        {
            "name": "owner",
            "label": "Responsable",
            formatter: Backoffice.formatters.linkObjectId("userAgency", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        },
        {
            "name": "client",
            "label": "Client",
            formatter: Backoffice.formatters.linkObjectId("companies", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        }
    ],
    loadSingle: loadSingle,
    fields: [
        {
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "name",
        "label": "Nom"
    }, {
        "name": "nameSlug",
        "label": "Nom formaté"
    }, {
        "name": "agencyId",
        "label": "Agence",
        formatter: Backoffice.formatters.linkObjectId("agencies", {
            "label": context.agencies.formatter()
        }),
        editor: context.agencies.editor()
    }, {
        condition: item => {
            return (item.status === constants.MissionStatus.companyAsk);
        },
        "name": "ownerId",
        "label": "Responsable",
        formatter: Backoffice.formatters.linkObjectId("userCompany", {
            "label": context.users.formatter()
        }),
        editor: context.users.editor()
    }, {
        condition: item => {
            return (item.status !== constants.MissionStatus.companyAsk);
        },
        "name": "ownerId",
        "label": "Responsable agence",
        formatter: Backoffice.formatters.linkObjectId("userAgency", {
            "label": context.users.formatter()
        }),
        editor: context.users.editor()
    }, {
        condition: item => {
            return (item.companyOwnerId);
        },
        "name": "companyOwnerId",
        "label": "Responsable client",
        formatter: Backoffice.formatters.linkObjectId("userCompany", {
            "label": context.users.formatter()
        })
    }, {
        "name": "clientId",
        "label": "Client",
        formatter: Backoffice.formatters.linkObjectId("companies", {
            "label": context.companies.formatter()
        }),
        editor: context.companies.editor()
    }, {
        "name": "status",
        "label": "Statut"
    }, {
        "name": "address",
        "label": "Adresse",
        children: [
            {
                name: "street",
                label: "Rue"
            }, {
                name: "complement",
                label: "Complément"
            }, {
                name: "city",
                label: "Ville"
            }, {
                name: "citySlug",
                label: "Ville formatée"
            }, {
                name: "zip",
                label: "Code postal"
            }, {
                name: "countryCode",
                label: "Code pays",
                "readOnly": true
            }, {
                name: "department",
                label: "Département",
                "readOnly": true
            }, {
                name: "region",
                label: "Région",
                "readOnly": true
            },
        ]
    }, {
        "name": "description",
        "label": "Description",
        "formatters": Backoffice.formatters.multiLines(),
        "editor": {
            type: "text",
            lines: 10
        }
    }, {
        "name": "periods",
        "label": "Périodes",
        list: true,
        children: [
            {
                "name": "start",
                "label": "Début",
                "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
                editor: {
                    type: "date"
                }
            },
            {
                "name": "end",
                "label": "Fin",
                "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
                editor: {
                    type: "date"
                }
            }, {
                "name": "weekendIncluded",
                "label": "Week-end inclus",
                formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                editor: {
                    "type": "boolean",
                    "labels": ["Non", "Oui"]
                }
            }, {
                "name": "slots",
                "label": "Créneaux horaires",
                children: [
                    {
                        "name": "all",
                        "label": "Tous",
                        list: true,
                        children: [
                            {
                                "name": "from",
                                "label": "Depuis"
                            },
                            {
                                "name": "to",
                                "label": "Jusqu'à"
                            }
                        ]
                    }
                ]
            }
        ]
    }, {
        "name": "positions",
        "label": "Positions",
        list: true,
        children: [
            {
                "name": "jobId",
                "label": "Métier",
                formatter: Backoffice.formatters.linkObjectId("jobs", {
                    "label": context.jobs.formatter()
                }),
                editor: context.jobs.editor()
            },
            {
                "name": "experience",
                "label": "Expérience",
                formatter: Backoffice.formatters.mapping({
                    "none": "Aucune",
                    "beginner": "Débutant",
                    "intermediate": "Intermédiaire",
                    "advanced": "Confirmé",
                    "expert": "Expert"
                }),
                editor: {
                    "type": "select",
                    "options": [{
                        "value": null,
                        "label": "-"
                    }, {
                        "value": "none",
                        "label": "Aucune"
                    }, {
                        "value": "beginner",
                        "label": "Débutant"
                    }, {
                        "value": "intermediate",
                        "label": "Intermédiaire"
                    }, {
                        "value": "advanced",
                        "label": "Confirmé"
                    }, {
                        "value": "expert",
                        "label": "Expert"
                    }]
                }
            }, {
                "name": "quantity",
                "label": "Quantité"
            }, {
                "name": "certifications",
                "label": "Certifications",
                list: true,
                children: [
                    {
                        "name": "_item",
                        "noLabel": true,
                        formatter: Backoffice.formatters.linkObjectId("certifications", {
                            "label": context.certifications.formatter()
                        }),
                        editor: context.certifications.editor()
                    }
                ]
            }, {
                "name": "description",
                "label": "Description",
                "formatters": Backoffice.formatters.multiLines(),
                "editor": {
                    type: "text",
                    lines: 10
                }
            }, {
                "name": "hourlyWage",
                "label": "Salaire horaire",
                formatter: function (property, item) {
                    return item.hourlyWage / 100 + " €";
                },
                editor: {
                    "type": "float",
                    "mode": "price"
                }
            }
        ]
    }, {
        "name": "autoConfirmation",
        "label": "Auto-confirmation",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "warnings",
        "label": "Alertes",
        list: true,
        children: []
    }, {
        "name": "cancelReason",
        "label": "Raison d'annulation",
        formatter: Backoffice.formatters.mapping({
            "Le besoin du client n\'existe plus": "Le besoin du client n\\'existe plus",
            "Autre": "Autre",
            "Aucun intérimaire ne peut réaliser la mission": "Aucun intérimaire ne peut réaliser la mission",
            "Concurrence plus rapide": "Concurrence plus rapide",
            "Modifications client(date/horaire...)": "Modifications client(date/horaire...)",
            "L'intérimaire ne s'est pas présenté": "L\'intérimaire ne s\'est pas présenté"
        }),
        editor: {
            "type": "select",
            "options": [{
                "value": null,
                "label": "-"
            }, {
                "value": "Le besoin du client n\\'existe plus",
                "label": "Le besoin du client n\\'existe plus"
            }, {
                "value": "Autre",
                "label": "Autre"
            },
                {
                    "value": "Aucun intérimaire ne peut réaliser la mission",
                    "label": "Aucun intérimaire ne peut réaliser la mission"
                }, {
                    "value": "Concurrence plus rapide",
                    "label": "Concurrence plus rapide"
                }, {
                    "value": "Modifications client(date/horaire...)",
                    "label": "Modifications client(date/horaire...)"
                }, {
                    "value": "L\'intérimaire ne s\'est pas présenté",
                    "label": "L\'intérimaire ne s\'est pas présenté"
                }]
        }
    }, {
        condition: item => {
            return item.cancelReason === constants.CancelReason.other;
        },
        "name": "cancelReasonOther",
        "label": "Raison d'annulation autre"
    }, {
        "name": "workers",
        "label": "Intérimaires",
        list: true,
        children: [
            {
                "name": "workerId",
                "label": "Intérimaire",
                formatter: Backoffice.formatters.linkObjectId("worker", {
                    "label": context.users.formatter()
                }),
                editor: context.users.editor()
            },
            {
                "name": "status",
                "label": "Statut",
                formatter: Backoffice.formatters.mapping({
                    "placed": "Placé",
                    "presented": "Présenté à l'entreprise",
                    "validated": "Validé par l'entreprise",
                    "proposed": "Proposé à l'interimaire",
                    "accepted": "Accepté par l'intérimaire",
                    "declined": "Décliné par l'intérimaire",
                    "confirmed": "Confirmé par l'agence",
                    "rejected": "Non confirmé par l'agence ou l'entreprise",
                    "aborted": "Annulé"
                }),
                editor: {
                    "type": "select",
                    "options": [{
                        "value": null,
                        "label": "-"
                    }, {
                        "value": "placed",
                        "label": "Placé"
                    }, {
                        "value": "presented",
                        "label": "Présenté à l'entreprise"
                    },
                        {
                            "value": "validated",
                            "label": "Validé par l'entreprise"
                        }, {
                            "value": "proposed",
                            "label": "Proposé à l'interimaire"
                        }, {
                            "value": "accepted",
                            "label": "Accepté par l'intérimaire"
                        }, {
                            "value": "declined",
                            "label": "Décliné par l'intérimaire"
                        }, {
                            "value": "confirmed",
                            "label": "Confirmé par l'agence"
                        }, {
                            "value": "rejected",
                            "label": "Non confirmé par l'agence ou l'entreprise"
                        }, {
                            "value": "aborted",
                            "label": "Annulé"
                        }]
                }
            },
            {
                "name": "positionIndex",
                "label": "Indice dans positions"
            }, {
                "name": "firstname",
                "label": "Prénom"
            }, {
                "name": "lastname",
                "label": "Nom"
            }, {
                "name": "periodIndex",
                "label": "Indice dans périodes"
            }, {
                "name": "slot",
                "label": "Créneau horaires"
            }, {
                "name": "error",
                "label": "Erreur"
            }, {
                "name": "availableCommands",
                "label": "Commandes disponibles",
                list: true,
                children: [
                    {
                        "name": "_item",
                        "noLabel": true
                    }
                ]
            }
        ]
    }, {
        "name": "evaluation",
        "label": "Évaluation"
    }, {
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Mofifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }]
};
export default crud;

function completeSingle(item) {
    if (item.periods === undefined) item.periods = [];
    if (item.positions === undefined) item.positions = [];
    if (item.workers === undefined) item.workers = [];
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.companies.load(), context.agencies.load(), context.users.load(), context.certifications.load(), context.jobs.load()]);
}