import Backoffice from "@snark/backoffice";

export default {
	name: "bouser",
	collection: "bouser",
	idProperty: "_id",
	section: "backoffice",
	labels: {
		"listTitle": "Utilisateurs Backoffice",
		"singleTitle": "Utilisateur Backoffice"
	},
	emptyItem: {
	},
	listColumns: [
		{
			"name": "username",
			"label": "Username"
		}
	],
	fields: [
		{
			"name": "_id",
			"label": "Identifiant",
			"readOnly": true,
			"hideOnCreate": true
		},
		{
			"name": "username",
			"label": "Username"
		},
		{
			"name": "password",
			"label": "Nouveau mot de passe",
			writeOnly: true
		},
		{
			"name": "createdAt",
			"label": "Créé le",
			"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
			"readOnly": true,
			"hideOnCreate": true
		},
		{
			"name": "updatedAt",
			"label": "Mofifié le",
			"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
			"readOnly": true,
			"hideOnCreate": true
		}
	]
};
