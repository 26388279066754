<script>
import axios from "axios";

export default {
    name: 'BlockResetGeocoding',
    props: {},

    data() {
        return {
            resetting: false,
            done: false
        };
    },

    methods: {
        async reset() {
            console.log("RESET");
            this.resetting = true;
            this.done = false;

            await axios.post(process.env.VUE_APP_API_URL + "/reset-geocoding", {});

            this.done = true;
            this.resetting = false;
        }
    }

}
</script>

<template>
    <div class="BlockResetGeocoding">
        <p>En cliquant sur le bouton, vous allez remettre à zéro les états de géocodage des adresses des utilisateurs
        et des clients.</p>
        <p>Cela forcera le module de Géocodage à réssayer d'encoder toutes les adresses non trouvées jusque là.</p>
        <div><p class="warning">Attention: Cela peut prendre plusieurs heures.</p></div>

        <div class="controller">
            <div v-if="resetting" class="resetting">mise à jour en cours...</div>
            <a v-else class="Button" @click.prevent="reset">Remettre à zéro</a>
        </div>
        <div v-if="done" class="success">Mise à jour effectuée.</div>
    </div>
</template>


<style lang="scss">
.BlockResetGeocoding {
    .warning {
        display: inline-block;
        color: #e33232;
        padding: 10px 0;
        font-weight: bold;
    }

    .controller {
        margin-top: 15px;

        .resetting {
            display: inline-block;
            background-color: #2e5b9910;
            color: black;
            padding: 10px 20px;
            border-radius: 15px;
        }
    }

    .success {
        margin-top: 15px;
        font-weight: bold;
        color: #3ab54a;
    }
}
</style>