import Backoffice from "@snark/backoffice";
import { subscriptionType } from "@/helpers/constants";
import axios from 'axios';
import ModalSynchronizeOne from "@/components/ModalSynchronizeOne";
import { FeatureEnum } from "../helpers/constants";

const context = {
    businessSectors: Backoffice.populator("business_sectors", { nullable: true }),
    jobs: Backoffice.populator("jobs", { nullable: true })
};

let crud = {
    name: "agencies",
    collection: "agencies",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Agences",
        "singleTitle": "Agence"
    },
    emptyItem: {
        "address": {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            contractManagerIdentifier: null,
            location: []
        },
        "numbers": [],
        "survey": {
            mainBusinessSectors: [],
            developingBussinessSectors: [],
            jobsRequiringMissions: [],
            jobsRequiringWorkers: [],
            clientSoftwares: '',
            dematerializationSoftwares: ''
        },
        "socialNetworks": {
            linkedin: '',
            twitter: '',
            facebook: ''
        },
        "subscriptionType": "full",
        "invitationCode": "",
        "features": []
    },
    sort: {
        back: [{ field: "name" }]
    },
    listTextFilter: ['name', 'address.city'],
    listSelectFilter: [{ label: 'Tous', value: '-' }, { label: 'Complète', value: 'full' }, {
        label: 'Intermédiaire',
        value: 'intermediate'
    }, { label: 'Starter', value: 'starter' }],
    listSelectFilterValue: "-",
    listSelectFilterFunction: (item, selectValue) => {
        if (selectValue !== '-') {
            return item.subscriptionType === selectValue;
        } else {
            return item;
        }
    },
    completeSingle,
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "address",
            "label": "Adresse",
            formatter: function (property, item) {
                let address = '';
                if (item.address) {
                    if (item.address.street !== undefined) {
                        address = address + item.address.street;
                    }
                    if (item.address.complement !== undefined) {
                        address = address + ' ' + item.address.complement;
                    }
                    if (item.address.zipcode !== undefined) {
                        address = address + ' ' + item.address.zipcode;
                    }
                    if (item.address.city !== undefined) {
                        address = address + ' ' + item.address.city;
                    }
                    if (item.address.countryCode !== undefined) {
                        address = address + ' ' + item.address.countryCode;
                    }
                    return address;
                }
            }
        },
        {
            "name": "subscriptionType",
            "label": "Type de souscription",
            formatter: Backoffice.formatters.mapping(subscriptionType)
        },
        {
            "label": "Marque",
            "name": "brand"
        }
    ],
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        }, {
            "label": "Marque",
            "name": "brand",
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "",
                        "label": "Aucune"
                    },
                    {
                        "value": "interaction",
                        "label": "Interaction"
                    }
                ]
            }
        }, {
            "name": "subBrand",
            "label": "Sous Marque",
        }, {
            "name": "name",
            "label": "Nom"
        }, {
            "name": "interactionWebName",
            "label": "Nom Interaction Web"
        }, {
            "name": "nameSlug",
            "label": "Nom formaté",
            "readOnly": true
        }, {
            "name": "address",
            "label": "Adresse",
            children: [
                {
                    "name": "name",
                    "label": "Nom"
                },
                {
                    "name": "street",
                    "label": "Rue"
                },
                {
                    "name": "complement",
                    "label": "Complément"
                },
                {
                    "name": "city",
                    "label": "Ville"
                },
                {
                    "name": "citySlug",
                    "label": "Ville formatée",
                    "readOnly": true
                },
                {
                    "name": "zip",
                    "label": "Code postal"
                },
                {
                    "name": "countryCode",
                    "label": "Code pays",
                    "readOnly": true
                }, {
                    name: "department",
                    label: "Département",
                    "readOnly": true
                }, {
                    name: "region",
                    label: "Région",
                    "readOnly": true
                },
                {
                    "name": "location",
                    "label": "Localisation",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true
                        }
                    ],
                    "readOnly": true
                },
            ]
        }, {
            "name": "phone",
            "label": "Téléphone"
        }, {
            "name": "logo",
            "label": "Logo",
            formatter: Backoffice.formatters.picture({
                urlPrefix: process.env.VUE_APP_CDN_URL,
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256,
                    urlPrefix: process.env.VUE_APP_CDN_URL
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        }, {
            "name": "video",
            "label": "Video",
            editor: {
                type: 'file',
                accept: ['video/web', 'video/avi', 'video/mpeg4', 'video/wav']
            }
        }, {
            "name": "numbers",
            "label": "Chiffres",
            list: true,
            children: [
                {
                    "name": "label",
                    "label": "Label"
                },
                {
                    "name": "value",
                    "label": "Valeur"
                }
            ]
        }, {
            condition: item => {
                return item.contractManagerType;
            },
            "name": "deployment",
            "label": "Déploiement",
            children: [
                {
                    "name": "done",
                    "label": "Terminé",
                    formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                    editor: {
                        "type": "boolean",
                        "labels": ["Non", "Oui"]
                    }
                },
                {
                    "name": "phases",
                    "label": "Phases",
                    list: true,
                    children: [
                        {
                            "name": "done",
                            "label": "Terminé",
                            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
                            editor: {
                                "type": "boolean",
                                "labels": ["Non", "Oui"]
                            }
                        },
                        {
                            "name": "text",
                            "label": "Texte"
                        },
                        {
                            "name": "filePath",
                            "label": "Fichier",
                            formatter: downloadLink,
                            editor: {
                                type: "file",
                                accept: "application/pdf"
                            }
                        },
                        {
                            "name": "fileName",
                            "label": "Nom du fichier"
                        },
                        {
                            "name": "phase",
                            "label": "Phase",
                            formatter: Backoffice.formatters.mapping({
                                "diagnosis": "Diagnostique",
                                "setup": "Mise en route",
                                "training": "Formation",
                                "followup": "Accompagnement"
                            }),
                            readOnly: true
                        },
                        {
                            "name": "step",
                            "label": "Étape",
                            formatter: Backoffice.formatters.mapping({
                                "diagnosis": "Diagnostique",
                                "form": "Questionnaire",
                                "infography": "Infographie",
                                "workers": "Importation vivier",
                                "clients": "Importation prtefeuille",
                                "firststep": "M1: premiers pas",
                                "setup": "M2: Mise en route",
                                "ats": "M3: recrutement",
                                "tendays": "Suivi 10j",
                                "thirtydays": "Suivi 30j",
                                "ninetydays": "Suivi 90j"
                            }),
                            readOnly: true
                        }
                    ]
                }
            ]
        }, {
            "name": "description",
            "label": "Description",
            "formatters": Backoffice.formatters.multiLines(),
            "editor": {
                type: "text",
                lines: 10
            }
        }, {
            "name": "subscriptionType",
            "label": "Type de souscription",
            formatter: Backoffice.formatters.mapping(subscriptionType),
            editor: {
                "type": "select",
                "options": selectOptions(subscriptionType)
            }
        }, {
            "name": "siret",
            "label": "Siret"
        }, {
            "name": "state",
            "label": "État",
            "readOnly": true
        }, {
            "name": "website",
            "label": "Site web"
        }, {
            "name": "email",
            "label": "Email"
        }, {
            "name": "socialNetworks",
            "label": "Réseaux sociaux",
            children: [
                {
                    "name": "linkedin",
                    "label": "Linkedin"
                },
                {
                    "name": "twitter",
                    "label": "Twitter"
                },
                {
                    "name": "facebook",
                    "label": "Facebook"
                }
            ]
        }, {
            "name": "survey",
            "label": "Expertise",
            children: [
                {
                    "name": "mainBusinessSectors",
                    "label": "Principaux secteurs d'activités",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("business_sectors", {
                                "label": context.businessSectors.formatter()
                            }),
                            editor: context.businessSectors.editor()
                        }
                    ]
                },
                {
                    "name": "developingBussinessSectors",
                    "label": "Secteurs d'activités en développement",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("business_sectors", {
                                "label": context.businessSectors.formatter()
                            }),
                            editor: context.businessSectors.editor()
                        }
                    ]
                },
                {
                    "name": "jobsRequiringMissions",
                    "label": "Métiers nécessitant des missions",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("jobs", {
                                "label": context.jobs.formatter()
                            }),
                            editor: context.jobs.editor()
                        }]
                },
                {
                    "name": "jobsRequiringWorkers",
                    "label": "Métiers nécessitant des intérimaires",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.linkObjectId("jobs", {
                                "label": context.jobs.formatter()
                            }),
                            editor: context.jobs.editor()
                        }]
                },
                {
                    "name": "clientSoftwares",
                    "label": "Logiciels utilisés pour la partie client"
                },
                {
                    "name": "dematerializationSoftwares",
                    "label": "Logiciels uitilisés pour la partie dématérialisation"
                }
            ]
        }, {
            "name": "invitationCode",
            "label": "Code d'invitation",
            "readOnly": true
        }, {
            "name": "delegationDepartments",
            "label": "Départements de délégation",
            list: true
        }, {
            name: 'contractManagerType',
            label: 'Gestionnaire de contrat',
            editor: {
                type: "select",
                options: [
                    { label: "Aucun", value: null },
                    { label: 'Evolia', value: 'EVOLIA' }]
            }
        },
        {
            name: 'contractManagerIdentifier',
            label: 'Identifiant du gestionnaire de contrat',
            watch: 'contractManagerType',
            condition: function (item) {
                return item.contractManagerType !== null
            }
        },
        {
            name: 'otherCMIdentifiers',
            label: 'Autre identifiant du gestionnaire de contrat',
            watch: 'contractManagerType',
            list: true,
            condition: function (item) {
                return item.contractManagerType !== null
            },
            children: [
                {
                    name: "_item",
                    noLabel: true,
                    placeholder: "Evolia ID"
                }
            ]
        },
        {
            "name": "features",
            "label": "Fonctionnalités",
            list: true,
            editor: {
                type: "checks",
                options: [
                    {
                        "value": FeatureEnum.ats,
                        "label": "Recrutement"
                    },
                    {
                        "value": FeatureEnum.ats_job_offers,
                        "label": "Campagnes"
                    },
                    {
                        "value": FeatureEnum.notifier,
                        "label": "Notifications"
                    },
                    {
                        "value": FeatureEnum.salesforce,
                        "label": "Salesforce"
                    },
                    {
                        "value": FeatureEnum.evolia_orders,
                        "label": "Commandes vers Evolia"
                    }
                ],
                sort: "none"
            },
        },
        {
            "name": "talentplugEmailRedirection",
            "label": "Email de redirection Talentplug"
        },
        {
            "name": "talentplugLogin",
            "label": "Identifiant Talentplug"
        },
        {
            "name": "talentplugPassword",
            "label": "Mot de passe Talentplug"
        },
        {
            "name": "talentplugCompanyId",
            "label": "Identifiant entreprise Talentplug",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }, {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }],
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                field: '_id',
                frontUrl: process.env.VUE_APP_LOGAS_FRONT,
                apiBase: process.env.VUE_APP_API_URL
            }
        },
        {
            label: "Lancer la migration du portefeuille",
            check: (agency) => {
                return (agency.contractManagerIdentifier !== null) && (agency.contractManagerIdentifier !== undefined);
            },
            action: async function (item) {
                if (item.contractManagerIdentifier) {
                    try {
                        let result = await axios.post(process.env.VUE_APP_SYNCHRO_URL + '/allcompaniessynchroByAgency', { "contractManagerIdentifier": [item.contractManagerIdentifier] });
                        console.log("RESULT: ", result);
                        alert("Migration portefeuille lancée");
                        return Promise.resolve({ message: "La migration est lancée" });
                    }
                    catch (err) {
                        console.log("ERROR: ", err);
                        return Promise.reject("Impossible de lancer la migration.");
                    }
                } else {
                    alert("Code evolia manquant");
                }
            }
        },
        {
            label: "Lancer la migration du vivier intérimaires",
            check: (agency) => {
                return (agency.contractManagerIdentifier !== null) && (agency.contractManagerIdentifier !== undefined);
            },
            action: async function (item) {
                if (item.contractManagerIdentifier) {
                    try {
                        let result = await axios.post(process.env.VUE_APP_SYNCHRO_URL + '/allinterimssynchroByAgency', { "contractManagerIdentifier": [item.contractManagerIdentifier] });
                        console.log("RESULT: ", result);
                        alert("Migration vivier lancée");
                        return Promise.resolve({ message: "La migration est lancée" });
                    }
                    catch (err) {
                        console.log("ERROR: ", err);
                        return Promise.reject("Impossible de lancer la migration.");
                    }
                } else {
                    alert("Code evolia manquant");
                }
            }
        },
        {
            label: "Synchroniser un client ou un intérimaire depuis Evolia",
            check: (agency) => {
                return (agency.contractManagerIdentifier !== null) && (agency.contractManagerIdentifier !== undefined);
            },
            action: async function (agency) {
                this.appendComponent(ModalSynchronizeOne, this, {
                    agency,
                    onTerminated: (message) => {
                        resolve({ message });
                    }
                });
            }
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    if (item.numbers === undefined) {
        item.numbers = [];
    }
    if (item.survey === undefined) {
        item.survey = {
            mainBusinessSectors: [],
            developingBussinessSectors: [],
            jobsRequiringMissions: [],
            jobsRequiringWorkers: [],
            clientSoftwares: '',
            dematerializationSoftwares: ''
        }
    }
    if (item.socialNetworks === undefined) {
        item.socialNetworks = {
            linkedin: '',
            twitter: '',
            facebook: ''
        }
    }
    if (item.invitationCode === undefined) {
        item.invitationCode = '';
    }
    if (item.features === undefined) {
        item.features = [];
    }
    if (item.contractManagerType === undefined) {
        item.contractManagerType = null;
    }
    if (item.otherCMIdentifiers === undefined) {
        item.otherCMIdentifiers = [];
    }

    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.businessSectors.load(), context.jobs.load()]);
}

function selectOptions(list) {
    return Object.entries(list).map(([key, value]) => ({
        label: value,
        value: key,
    }));
}

function downloadLink(property, item) {
    let filePath = item[property.name];
    if (filePath) {
        return '<a href="' + process.env.VUE_APP_CDN_URL + filePath + '">' + item.fileName + '</a>';
    } else {
        return "-";
    }
}