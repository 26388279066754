<script>
import axios from "axios";

export default {
    name: 'modal-merge-job',
    props: {
        onTerminated: Function,
        agency: Object
    },
    data() {
        return {
            item: {
                itemType: "worker",
                itemId: null,
            },
            error: null
        };
    },

    computed: {
        typeOptions() {
            return [
                {label: "Intérimaire", value: "worker"},
                {label: "Client", value: "company"}
            ];
        },
        buttons() {
            return [
                {
                    label: 'Synchronizer',
                    action: this.synchronize
                }
            ];
        }
    },
    methods: {
        async synchronize() {
            this.error = null;

            if(this.item.itemId.trim().length === 0) {
                this.error = "Vous devez spécifier l'identifiant de l'élément à synchroniser.";
                return;
            }

            try {
                const result = await axios.post(process.env.VUE_APP_API_URL + "/synchronize/item", {
                    itemType: this.item.itemType,
                    itemId: this.item.itemId,
                    agencyId: this.agency._id,
                    agencyCode: this.agency.contractManagerIdentifier
                });
                if (result.data._id) {
                    this.$refs.modal.close();
                    if (this.onTerminated) {
                        this.onTerminated("L'élément a été créé ou mis à jour.");
                    }
                }
                else {
                    this.error = result.message;
                }
            }
            catch(error) {
                console.log(error.response.status, error);
                if(error.response.status === 422) {
                    this.error = error.response.data.map(e => (e.context + ": " + e.id)).join("<br/>");
                }
                else {
                    this.error = error;
                }
            }
        }
    }
};
</script>

<template>
    <modal ref="modal" class="modal-synchronize-one" title="Synchroniser un client ou un intérimaire" :buttons="buttons">
        <field-editor property="itemType" :options="{label: 'Type d\'élément', name: 'itemType', editor: {type: 'select', options: typeOptions}}" :object="item" v-model="item"/>
        <field-editor property="itemId" :options="{label: 'Identifiant Evolia (IdClient ou IDPersonne)', name: 'itemId', editor: {type: 'text'}}" :object="item" v-model="item"/>
        <div v-if="error" class="error" v-html="error"/>
    </modal>
</template>


<style lang="scss">
.ModalSynchronizeOne {

}
</style>