import Backoffice from "@snark/backoffice";
import ModalMergeCertification from "@/components/ModalMergeCertification";

const context = {
    jobs: Backoffice.populator("jobs", {nullable: true})
};

let crud = {
    name: "certifications",
    idProperty: "_id",
    section: "data",
    labels: {
        "listTitle": "Certifications",
        "singleTitle": "Certification"
    },
    emptyItem: {
        "nameSlug": [],
        "verified": false,
        "jobs": []
    },
    sort: {
        back: [{field: "name"}]
    },
    pagination: 100,
    backTextFilterList: true,
    // listTextFilter: ['name'],
    completeSingle: completeSingle,
    listColumns: [{
        "name": "name",
        "label": "Nom"
    }, {
        "name": "verified",
        "label": "Vérifié",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "jobsList",
        "label": "Métiers",
        formatter: jobFormatter
    }],
    backPopulateList: ["jobsList"],
    loadSingle: loadSingle,
    fields: [{
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "name",
        "label": "Nom"
    }, {
        "name": "verified",
        "label": "Vérifié",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "nameSlug",
        "label": "Noms formatés",
        readOnly: true,
        list: true,
        children: [{
            "name": "_item",
            "noLabel": true
        }],
        listEmptyItem: []
    }, {
        "name": "jobs",
        "label": "Métiers",
        list: true,
        children: [
            {
                "name": "_item",
                "noLabel": true,
                formatter: Backoffice.formatters.linkObjectId("jobs", {
                    "label": context.jobs.formatter()
                }),
                editor: context.jobs.editor()
            }
        ]

    }, {
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Modifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }],
    viewActions: [
        {
            label: "Fusionner cette certification avec une autre",
            action: mergeCertification
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.nameSlug === undefined) item.nameSlug = [];
    if (item.verified === undefined) item.verified = false;
    if (item.jobs === undefined) item.jobs = [];
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.jobs.load()]);
}

function mergeCertification(certification) {
    return new Promise((resolve) => {
        this.appendComponent(ModalMergeCertification, this, {
            certification: certification,
            onTerminated: () => {
                this.$router.push({name: 'certificationsList', params: {}});
                resolve();
            }
        });
    });
}

function jobFormatter(field, item) {
    const jobs = item[field.name];
    if(jobs && (jobs.length > 0)) {
        return "<ul><li>" + jobs.map(j => (j.name)).join("</li><li>") + "</li></ul>";
    }
    else {
        return  "-";
    }
}

