import Backoffice from "@snark/backoffice";
import * as constants from '../helpers/constants';
import moment from 'moment-timezone';

const context = {
    businessSectors: Backoffice.populator("business_sectors", {nullable: true}),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if(item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            }
            else {
                return item.name;
            }
        },
        nullable: true
    }),
    jobs: Backoffice.populator("jobs", {nullable: true}),
    certifications: Backoffice.populator("certifications", {nullable: true}),
    users: Backoffice.populator("users", {
        filter: function (user) {
            return ((user.role === constants.UserRole.Collaborator) || (user.role === constants.UserRole.Manager));
        },
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    }),
    unavailabilities: Backoffice.populator("unavailabilities", {nullable: true}, {
        displayProperty: item => {
            return moment(item.date).format('DD/MM/YYYY');
        }
    })
};
let crud = {
    name: "userAgency",
    collection: "users",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Collaborateurs (agence)",
        "singleTitle": "Collaborateur (agence)"
    },
    emptyItem: {
        businessSectors: [],
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        },
        status: constants.userStatus.registered,
        role: 'collaborator',
        notifications: {
            mission_ask_agency: ['email'],
            mission_evaluated: ['email'],
            worker_abort: ['email'],
            worker_accepted: ['email'],
            worker_declined: ['email'],
            worker_join_agency: ['email'],
            worker_leave_agency: ['email'],
            worker_registration: ['email'],
            worker_rejected_by_client: ['email'],
            worker_validated: ['email']
        }
    },
    sort: {
        back: [
            {field: "lastname"}
        ]
    },
    pagination: 100,
    backFilter: {
        role: "$in:" + [constants.UserRole.Collaborator, constants.UserRole.Manager].join(","),
        state: "$ne:deleted"
    },
    backPopulateList: ["agency"],
    // filterList: context.agencies.completeList("agencyId", "agency"),
    backTextFilterList: true,
    // listTextFilter: ['firstname', 'lastname', 'username', 'address.city', 'agency.name'],
    completeSingle: completeSingle,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_URL
            }
        }
    ],
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "collaborator": "Collaborateur",
                "manager": "Responsable"
            })
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "agency",
            "label": "Agence",
            formatter: Backoffice.formatters.linkObjectId("agencies", {
                idProperty: "_id",
                "label": Backoffice.formatters.subProperty("name")
            }),
        }
    ],
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "collaborator": "Collaborateur",
                "manager": "Responsable"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "collaborator",
                        "label": "Collaborateur"
                    },
                    {
                        "value": "manager",
                        "label": "Responsable"
                    }
                ]
            }
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "newPassword",
            "label": "Nouveau mot de passe"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        }, {
            "name": "position",
            "label": "Poste"
        },
        {
            "name": "agencyId",
            "label": "Agence",
            formatter: Backoffice.formatters.linkObjectId("agencies", {
                "label": context.agencies.formatter()
            }),
            editor: context.agencies.editor()
        },
        {
            "name": "businessSectors",
            "label": "Secteur d'activités",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("business_sectors", {
                        "label": context.businessSectors.formatter()
                    }),
                    editor: context.businessSectors.editor()
                }
            ]

        },
        {
            "name": "configuration",
            "label": "Configuration",
            readOnly: true
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "invited": "Invité",
                "reinvited": "Relancé",
                "registered": "Inscrit",
                "imported": "Importé",
                "no_longer_registered": "Plus inscrit"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        "value": "invited",
                        "label": "Invité"
                    },
                    {
                        "value": "registered",
                        "label": "Inscrit"
                    },
                    {
                        "value": "imported",
                        "label": "Importé"
                    },
                    {
                        "value": "no_longer_registered",
                        "label": "Plus inscrit"
                    }
                ]
            }
        },
        {
            "name": "firstConnectionToken",
            "label": "Token de première connexion",
            readOnly: true
        },
        {
            "name": "picture",
            "label": "Photo",
            formatter: Backoffice.formatters.picture({
                maxHeight: 200
            }),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            "name": "notifications",
            "label": "Notifications",
            children: [
                {
                    "name": "mission_ask_agency",
                    "label": "Mission demandée par un client",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "mission_evaluated",
                    "label": "Mission évaluée",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_abort",
                    "label": "L'intérimaire a annulé",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_accepted",
                    "label": "L'intérimaire a accepté",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_declined",
                    "label": "L'intérimaire a décliné",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_join_agency",
                    "label": "L'intérimaire a rejoint une agence",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_leave_agency",
                    "label": "L'intérimaire a supprimé une agence",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_registration",
                    "label": "Inscription d'un intérimaire",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_rejected_by_client",
                    "label": "L'intérimaire a été refusé par le client",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "worker_validated",
                    "label": "L'intérimaire est validé",
                    list: true,
                    children: [
                        {
                            "name": "_item",
                            "noLabel": true,
                            formatter: Backoffice.formatters.mapping({
                                "email": "Email"
                            }),
                            editor: {
                                "type": "select",
                                "options": [
                                    {
                                        "value": "email",
                                        "label": "Email"
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }
    if (item.status === undefined) {
        item.status = constants.userStatus.registered;
    }
    if (item.businessSectors === undefined) {
        item.businessSectors = [];
    }
    if (item.role === undefined) {
        item.role = 'collaborator';
    }
    if (item.notifications === undefined) {
        item.notifications = {
            mission_ask_agency: ['email'],
            mission_evaluated: ['email'],
            worker_abort: ['email'],
            worker_accepted: ['email'],
            worker_declined: ['email'],
            worker_join_agency: ['email'],
            worker_leave_agency: ['email'],
            worker_registration: ['email'],
            worker_rejected_by_client: ['email'],
            worker_validated: ['email']
        };
    }
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.agencies.load(), context.businessSectors.load()]);
//    return Promise.all([context.businessSectors.load(), context.agencies.load(), context.jobs.load(), context.certifications.load(), context.users.load(), context.unavailabilities.load()]);
}