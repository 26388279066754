import Backoffice from "@snark/backoffice";
import * as constants from "@/helpers/constants";

const context = {
    businessSectors: Backoffice.populator("business_sectors", {nullable: true}),
    agencies: Backoffice.populator("agencies", {
        displayProperty: item => {
            if(item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            }
            else {
                return item.name;
            }
        },
        nullable: true
    }),
    collaborators: Backoffice.populator("users", {nullable: true}, {
        filter: function (user) {
            return ((user.role === constants.UserRole.Collaborator) || (user.role === constants.UserRole.Manager));
        },
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    }),
    workers: Backoffice.populator("users", {
        filter: function (user) {
            return (user.role === constants.UserRole.Interim);
        },
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    }),
    missions: Backoffice.populator("missions", {nullable: true}),
    companies: Backoffice.populator("companies", {nullable: true}),
};

let crud = {
    name: "notifications",
    collection: "notifications",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Notifications",
        "singleTitle": "Notification"
    },
    emptyItem: {},
    pagination: 100,
    completeSingle: completeSingle,
    sort: {
        back: [{field: "createdAt"}]
    },
    // filterList: context.agencies.completeList("agencyId", "agency"),
    listTextFilter: ['agency.name', 'level', 'status'],
    listColumns: [{
        "name": "type",
        "label": "Type",
        formatter: Backoffice.formatters.mapping({
            "collaborator_invitation": "Invitation d'un collaborateur",
            "mission_evaluated": "Mission évaluée",
            "sender_confirm_mission_to_worker": "Envoi d'une confirmation de mission à un intérimaire",
            "sender_decline_mission_to_collaborator": "Envoi d'un refus de mission à un collaborateur",
            "sender_not_confirm_mission_to_worker": "Envoi d'une non confirmation de mission à un intérimaire",
            "sender_propose_mission_to_worker": "Envoi d'une proposition de mission à un intérimaire",
            "sender_reject_mission_to_worker": "Envoi d'un refus de mission à un intérimaire",
            "sender_sent_interim_invitation_reminder": "Envoi d'un rappel d'invitation à un intérimaire",
            "sender_sent_collaborator_invitation": "Envoi d'une invitation à un collaborateur",
            "sender_sent_interim_invitation": "Envoi d'une invitation à un intérimaire",
            "sender_sent_worker_validated": "Envoi d'une validation d'un intérimaire",
            "worker_abort": "L'intérimaire a annulé",
            "worker_accepted": "L'intérimaire a accepté",
            "worker_declined": "L'intérimaire a décliné",
            "worker_join_agency": "L'intérimaire a rejoint une agence",
            "worker_leave_agency": "L'intérimaire a supprimé une agence",
            "worker_registration": "Inscription d'un intérimaire",
            "worker_rejected_by_client": "L'intérimaire a été refusé par le client",
            "worker_validated": "L'intérimaire est validé",
            "user_forgot_password": "Mot de passe oublié",
            "company_mission_extended": "Extension d'une mission",
            "company_mission_to_evaluate": "Mission à évaluer",
            "mission_agency_declined": "Mission déclinée par l'agence",
            "mission_completed_company": "Mission complète",
            "mission_propose_extension": "Proposition d'extension de mission",
            "mission_without_agency_close_to_begin": "Mission proche sans agence",
            "mission_workers_arriving_tomorrow": "Mission dont les intérimaires arrivent demain",
            "sender_reject_worker_by_client": "Envoi d'un refus par le client à un intérimaire",
            "worker_presented": "Intérimaire présenté",
            "confirmed_mission_to_worker": "Mission confirmée à un intérimaire",
            "interim_invitation": "Invitation d'un intérimaire",
            "interim_invitation_reminder": "Rappel d'invitation d'un intérimaire",
            "mission_canceled": "Mission annulée",
            "mission_change_of_address": "Changement d'adresse de la mission",
            "mission_change_of_periods": "Changement de périodes de la mission",
            "mission_waiting_for_worker_answer": "Mission en attente de la réponse de l'intérimaire",
            "not_confirmed_mission_to_worker": "Mission non confirmée à un intérimaire",
            "proposed_mission_to_worker": "Mission proposée à un intérimaire",
            "rejected_mission_to_worker": "Mission refusée à un intérimaire",
            "welcome_worker": "Bienvenue à l'intérimaire",
            "worker_missed_mission": "L'intérimaire a manqué une mission",
            "worker_mission_extended": "Mission prolongée à l'intérimaire",
            "worker_mission_to_evaluate": "L'intérimaire doit évaluer la mission",
            "worker_update_agenda_reminder": "L'intérimaire doit mettre à jour son agenda",
            "agency_registration": "Inscription d'une agence",
            "company_registration": "Inscription d'un client",
            "mission_no_agency_matches": "Mission sans agence"
        })
    }, {
        "name": "level",
        "label": "Niveau",
        formatter: Backoffice.formatters.mapping({
            "info": "Information",
            "warning": "Avertissement",
            "alert": "Alerte"
        })
    },
        {
            "name": "agency",
            "label": "Agence",
            formatter: context.agencies.formatter()
        }
    ],
    loadSingle: loadSingle,
    fields: [{
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "type",
        "label": "Type",
        formatter: Backoffice.formatters.mapping({
            "collaborator_invitation": "Invitation d'un collaborateur",
            "mission_evaluated": "Mission évaluée",
            "sender_confirm_mission_to_worker": "Envoi d'une confirmation de mission à un intérimaire",
            "sender_decline_mission_to_collaborator": "Envoi d'un refus de mission à un collaborateur",
            "sender_not_confirm_mission_to_worker": "Envoi d'une non confirmation de mission à un intérimaire",
            "sender_propose_mission_to_worker": "Envoi d'une proposition de mission à un intérimaire",
            "sender_reject_mission_to_worker": "Envoi d'un refus de mission à un intérimaire",
            "sender_sent_interim_invitation_reminder": "Envoi d'un rappel d'invitation à un intérimaire",
            "sender_sent_collaborator_invitation": "Envoi d'une invitation à un collaborateur",
            "sender_sent_interim_invitation": "Envoi d'une invitation à un intérimaire",
            "sender_sent_worker_validated": "Envoi d'une validation d'un intérimaire",
            "worker_abort": "L'intérimaire a annulé",
            "worker_accepted": "L'intérimaire a accepté",
            "worker_declined": "L'intérimaire a décliné",
            "worker_join_agency": "L'intérimaire a rejoint une agence",
            "worker_leave_agency": "L'intérimaire a supprimé une agence",
            "worker_registration": "Inscription d'un intérimaire",
            "worker_rejected_by_client": "L'intérimaire a été refusé par le client",
            "worker_validated": "L'intérimaire est validé",
            "user_forgot_password": "Mot de passe oublié",
            "company_mission_extended": "Extension d'une mission",
            "company_mission_to_evaluate": "Mission à évaluer",
            "mission_agency_declined": "Mission déclinée par l'agence",
            "mission_completed_company": "Mission complète",
            "mission_propose_extension": "Proposition d'extension de mission",
            "mission_without_agency_close_to_begin": "Mission proche sans agence",
            "mission_workers_arriving_tomorrow": "Mission dont les intérimaires arrivent demain",
            "sender_reject_worker_by_client": "Envoi d'un refus par le client à un intérimaire",
            "worker_presented": "Intérimaire présenté",
            "confirmed_mission_to_worker": "Mission confirmée à un intérimaire",
            "interim_invitation": "Invitation d'un intérimaire",
            "interim_invitation_reminder": "Rappel d'invitation d'un intérimaire",
            "mission_canceled": "Mission annulée",
            "mission_change_of_address": "Changement d'adresse de la mission",
            "mission_change_of_periods": "Changement de périodes de la mission",
            "mission_waiting_for_worker_answer": "Mission en attente de la réponse de l'intérimaire",
            "not_confirmed_mission_to_worker": "Mission non confirmée à un intérimaire",
            "proposed_mission_to_worker": "Mission proposée à un intérimaire",
            "rejected_mission_to_worker": "Mission refusée à un intérimaire",
            "welcome_worker": "Bienvenue à l'intérimaire",
            "worker_missed_mission": "L'intérimaire a manqué une mission",
            "worker_mission_extended": "Mission prolongée à l'intérimaire",
            "worker_mission_to_evaluate": "L'intérimaire doit évaluer la mission",
            "worker_update_agenda_reminder": "L'intérimaire doit mettre à jour son agenda",
            "agency_registration": "Inscription d'une agence",
            "company_registration": "Inscription d'un client",
            "mission_no_agency_matches": "Mission sans agence"
        }),
        editor: {
            "type": "select",
            "options": [{
                "value": null,
                "label": "-"
            }, {
                "value": "collaborator_invitation",
                "label": "Invitation d'un collaborateur"
            }, {
                "value": "mission_evaluated",
                "label": "Mission évaluée"
            }, {
                "value": "sender_confirm_mission_to_worker",
                "label": "Envoi d'une confirmation de mission à un intérimaire"
            }, {
                "value": "sender_decline_mission_to_collaborator",
                "label": "Envoi d'un refus de mission à un collaborateur"
            }, {
                "value": "sender_not_confirm_mission_to_worker",
                "label": "Envoi d'une non confirmation de mission à un intérimaire"
            }, {
                "value": "sender_propose_mission_to_worker",
                "label": "Envoi d'une proposition de mission à un intérimaire"
            }, {
                "value": "sender_reject_mission_to_worker",
                "label": "Envoi d'un refus de mission à un intérimaire"
            }, {
                "value": "sender_sent_interim_invitation_reminder",
                "label": "Envoi d'un rappel d'invitation à un intérimaire"
            }, {
                "value": "sender_sent_collaborator_invitation",
                "label": "Envoi d'une invitation à un collaborateur"
            }, {
                "value": "sender_sent_interim_invitation",
                "label": "Envoi d'une invitation à un intérimaire"
            }, {
                "value": "sender_sent_worker_validated",
                "label": "Envoi d'une validation d'un intérimaire"
            }, {
                "value": "worker_abort",
                "label": "L'intérimaire a annulé"
            }, {
                "value": "worker_accepted",
                "label": "L'intérimaire a accepté"
            }, {
                "value": "worker_declined",
                "label": "L'intérimaire a décliné"
            }, {
                "value": "worker_join_agency",
                "label": "L'intérimaire a rejoint une agence"
            }, {
                "value": "worker_leave_agency",
                "label": "L'intérimaire a supprimé une agence"
            }, {
                "value": "worker_registration",
                "label": "Inscription d'un intérimaire"
            }, {
                "value": "worker_rejected_by_client",
                "label": "L'intérimaire a été refusé par le client"
            }, {
                "value": "worker_validated",
                "label": "L'intérimaire est validé"
            }, {
                "value": "user_forgot_password",
                "label": "Mot de passe oublié"
            }, {
                "value": "company_mission_extended",
                "label": "Extension d'une mission"
            }, {
                "value": "company_mission_to_evaluate",
                "label": "Mission à évaluer"
            }, {
                "value": "mission_agency_declined",
                "label": "Mission déclinée par l'agence"
            }, {
                "value": "mission_completed_company",
                "label": "Mission complète"
            }, {
                "value": "mission_propose_extension",
                "label": "Proposition d'extension de mission"
            }, {
                "value": "mission_without_agency_close_to_begin",
                "label": "Mission proche sans agence"
            }, {
                "value": "mission_workers_arriving_tomorrow",
                "label": "Mission dont les intérimaires arrivent demain"
            }, {
                "value": "sender_reject_worker_by_client",
                "label": "Envoi d'un refus par le client à un intérimaire"
            }, {
                "value": "worker_presented",
                "label": "Intérimaire présenté"
            }, {
                "value": "confirmed_mission_to_worker",
                "label": "Mission confirmée à un intérimaire"
            }, {
                "value": "interim_invitation",
                "label": "Invitation d'un intérimaire"
            }, {
                "value": "interim_invitation_reminder",
                "label": "Rappel d'invitation dun intérimaire"
            }, {
                "value": "mission_canceled",
                "label": "Mission annulée"
            }, {
                "value": "mission_change_of_address",
                "label": "Changement d'adresse de la mission"
            }, {
                "value": "mission_change_of_periods",
                "label": "Changement de périodes de la mission"
            }, {
                "value": "mission_waiting_for_worker_answer",
                "label": "Mission en attente de la réponse de l'intérimaire"
            }, {
                "value": "not_confirmed_mission_to_worker",
                "label": "Mission non confirmée à un intérimaire"
            }, {
                "value": "proposed_mission_to_worker",
                "label": "Mission proposée à un intérimaire"
            }, {
                "value": "rejected_mission_to_worker",
                "label": "Mission refusée à un intérimaire"
            }, {
                "value": "welcome_worker",
                "label": "Bienvenue à l'intérimaire"
            }, {
                "value": "worker_missed_mission",
                "label": "L'intérimaire a manqué une mission"
            }, {
                "value": "worker_mission_extended",
                "label": "Mission prolongée à l'intérimaire"
            }, {
                "value": "worker_mission_to_evaluate",
                "label": "L'intérimaire doit évaluer la mission"
            }, {
                "value": "worker_update_agenda_reminder",
                "label": "L'intérimaire doit mettre à jour son agenda"
            }, {
                "value": "agency_registration",
                "label": "Inscription d'un client"
            }, {
                "value": "company_registration",
                "label": "Inscription d'un client"
            }, {
                "value": "mission_no_agency_matches",
                "label": "Mission sans agence"
            }]
        }
    }, {
        "name": "items",
        "label": "Éléments",
        children: [
            {
                "name": "agencyId",
                "label": "Agence",
                formatter: Backoffice.formatters.linkObjectId("agencies", {
                    "label": context.agencies.formatter()
                }),
                editor: context.agencies.editor()
            },
            {
                "name": "missions",
                "label": "Missions",
                list: true,
                children: [{
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("missions", {
                        "label": context.missions.formatter()
                    }),
                    editor: context.missions.editor()
                }]
            }, {
                "name": "clients",
                "label": "Clients",
                list: true,
                children: [{
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("companies", {
                        "label": context.companies.formatter()
                    }),
                    editor: context.companies.editor()
                }]
            }, {
                "name": "workers",
                "label": "Intérimaires",
                children: [{
                    "name": "workerId",
                    "label": "Identifiant",
                    formatter: Backoffice.formatters.linkObjectId("worker", {
                        "label": context.workers.formatter()
                    }),
                    readOnly: true
                }, {
                    "name": "firstname",
                    "label": "Prénom",
                    readOnly: true
                }, {
                    "name": "lastname",
                    "label": "Nom",
                    readOnly: true
                }]
            }, {
                "name": "collaborators",
                "label": "Collaborateurs",
                list: true,
                children: [{
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("userAgency", {
                        "label": context.collaborators.formatter()
                    }),
                    editor: context.collaborators.editor()
                }]
            }
        ]
    }, {
        "name": "data",
        "label": "Données"
    }, {
        "name": "level",
        "label": "Niveau",
        formatter: Backoffice.formatters.mapping({
            "info": "Information",
            "warning": "Avertissement",
            "alert": "Alerte"
        }),
        editor: {
            "type": "select",
            "options": [{
                "value": null,
                "label": "-"
            }, {
                "value": "info",
                "label": "Information"
            }, {
                "value": "warning",
                "label": "Avertissment"
            },
                {
                    "value": "alert",
                    "label": "Alerte"
                }]
        }
    }, {
        "name": "status",
        "label": "Statut",
        formatter: Backoffice.formatters.mapping({
            "unread": "Non lu",
            "read": "Lu",
            "terminated": "Terminé"
        }),
        editor: {
            "type": "select",
            "options": [{
                "value": null,
                "label": "-"
            }, {
                "value": "unread",
                "label": "Non lu"
            }, {
                "value": "read",
                "label": "Lu"
            },
                {
                    "value": "terminated",
                    "label": "Terminé"
                }]
        }
    }, {
        "name": "terminationTimestamp",
        "label": "Date de fin",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY")
    }, {
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Mofifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }]
};
export default crud;

function completeSingle(item) {
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.agencies.load(), context.collaborators.load(), context.companies.load(), context.missions.load(), context.workers.load()]);
}