import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crud_bouser from "./crud/bouser";
import crud_departments from './crud/departments';
import crud_regions from './crud/regions';
import crud_business_sectors from './crud/businessSectors';
import crud_agencies from './crud/agencies';
import crud_companies from './crud/companies';
import crud_workers from './crud/workers';
import crud_userAgency from './crud/userAgency';
import crud_userCompany from './crud/userCompany';
import crud_jobs from './crud/jobs';
import crud_certifications from './crud/certifications';
import crud_notifications from './crud/notifications';
import crud_unavailabilities from './crud/unavailabilities';
import crud_missions from './crud/missions';
import crud_workers_without_agency from './crud/workersWithoutAgency';
import crud_companies_registrered from './crud/companiesRegistered';
import crud_workers_duplicates from './crud/workersDuplicates';
import crud_candidates from './crud/candidates';
import crud_jobOffers from './crud/jobOffers';
import crud_messageTemplates from './crud/messageTemplates';
import crud_interviews from './crud/interviews';
import crud_supervisors from './crud/userSupervisor';
import axios from "axios";
import BlockResetGeocoding from "./components/BlockResetGeocoding";
import crud_workers_deleted from './crud/workersDeleted';

Vue.config.productionTip = false;

Vue.use(Backoffice, {
    title: "Backoffice",
    apiUrl: process.env.VUE_APP_API_CRUD_URL,
    apiVersion: 2,
    debug: true,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: async function (login, password) {
        const result = await axios.post(process.env.VUE_APP_API_URL + "/bo/authenticate", {
            username: login,
            password: password
        });
        if(result.data.success) {
            return {token: result.data.token};
        }
        else {
            throw "unauthorized";
        }
    },
    crud: async function () {
        // backoffice
        Vue.crud(crud_bouser);
        Vue.crud(crud_agencies);
        Vue.crud(crud_companies);
        Vue.crud(crud_companies_registrered);
        Vue.crud(crud_workers);
        Vue.crud(crud_workers_without_agency);
        Vue.crud(crud_workers_duplicates);
        Vue.crud(crud_workers_deleted);
        Vue.crud(crud_userAgency);
        Vue.crud(crud_supervisors);
        Vue.crud(crud_userCompany);
        Vue.crud(crud_jobs);
        Vue.crud(crud_missions);
        Vue.crud(crud_departments);
        Vue.crud(crud_regions);
        Vue.crud(crud_business_sectors);
        Vue.crud(crud_certifications);
        Vue.crud(crud_notifications);
        Vue.crud(crud_unavailabilities);
        Vue.crud(crud_candidates);
        Vue.crud(crud_jobOffers);
        Vue.crud(crud_messageTemplates);
        Vue.crud(crud_interviews);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["base", "ATS", "data", "backoffice"],
    dashboardBlocks: [
        {
            title: "Reset des états de Geocodage",
            component: BlockResetGeocoding
        }
    ]
});

Vue.backoffice.start(Vue);
