import Backoffice from '@snark/backoffice';

let crud = {
    name: 'departments',
    section: 'data',
    idProperty: '_id',
    labels: {
        listTitle: 'Départements',
        singleTitle: 'Département'
    },

    // --- list
    sort: {
        back: [{field: "code"}]
    },
    listTextFilter: ['name'],
    completeSingle: completeSingle,
    listColumns: [
        {
            name: 'name',
            label: 'Nom'
        },
        {
            name: 'code',
            label: 'Code'
        },
        {
            name: 'regionCode',
            label: 'Code région'
        }
    ],

    // --- single
    fields: [
        {
            name: 'name',
            label: 'Nom'
        },
        {
            name: 'code',
            label: 'Code'
        },
        {
            name: 'regionCode',
            label: 'Code région'
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            formatter: Backoffice.formatters.date('DD/MM/YYYY'),
            readOnly: true
        },
        {
            name: 'updatedAt',
            label: 'Mis à jour le',
            formatter: Backoffice.formatters.date('DD/MM/YYYY'),
            readOnly: true
        }
    ],
    emptyItem: {}
};
export default crud;

function completeSingle(item) {
    return Promise.resolve(item);
}
