export const UserRole = {
    Collaborator: 'collaborator',
    Interim: 'interim',
    Manager: 'manager',
    Company: 'company',
    Supervisor: 'supervisor'
}

export const CancelReason = {
    clientNeedNotExist: 'Le besoin du client n\'existe plus',
    other: 'Autre',
    noInterimCanCarryOutMission: 'Aucun intérimaire ne peut réaliser la mission',
    fasterCompetition: 'Concurrence plus rapide',
    clientModifications: 'Modifications client(date/horaire...)',
    interimNotShowUp: 'L\'intérimaire ne s\'est pas présenté'
}

export const subscriptionType = {
    starter: 'Starter',
    intermediate: 'Intermédiaire',
    full: 'Complète'
}

export const userStatus = {
    invited: 'invited',
    reinvited: 'reinvited',
    registered: 'registered',
    imported: 'imported',
    noLongerRegistered: 'no_longer_registered'
}

export const NotificationType = {
    email: "Email",
    sms: "SMS"
}

export const MissionStatus = {
    draft: 'draft',
    created: 'created',
    companyAsk: 'companyAsk',
    declinedByAgency: "declinedByAgency",
    inPreparation: 'inPreparation',
    presented: 'presented',
    proposed: 'proposed',
    ready: 'ready',
    completed: 'completed',
    inProgress: 'inProgress',
    evaluation: 'evaluation',
    terminated: 'terminated',
    archived: 'archived',
    deleted: 'deleted',
    canceled: 'canceled',
    oldConfirmed: 'confirmed',
    oldTodo: 'todo',
    oldWaiting: 'waiting'
}

export const CandidateStatus = {
    new: 'new',
    rejected: 'rejected',
    integrated: 'integrated',
    interesting: 'interesting',
    meeting: 'meeting'
}
export const JobOfferStatus = {
    archived: 'archived',
    canceled: 'canceled',
    draft: 'draft',
    filled: 'filled',
    old: 'old',
    posted: 'posted'
}

export const MessageTemplateType = {
    ats_agency_message: "ats_agency_message",
    ats_agency_rejection: "ats_agency_rejection",
    ats_agency_interview: "ats_agency_interview"
}

export const FeatureEnum = {
    ats: "ats",
    ats_job_offers: "ats-job-offers",
    notifier: "notifier",
    salesforce: "salesforce",
    evolia_orders: "evolia-orders"
}