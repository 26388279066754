import Backoffice from "@snark/backoffice";
import * as constants from "@/helpers/constants";

const context = {
    users: Backoffice.populator("users", {
        backFilter: {role: constants.UserRole.Interim},
        // filter: function (user) {
        //     return (user.role === constants.UserRole.Interim);
        // },
        displayProperty: item => {
            return item.lastname + ' ' + item.firstname;
        },
        nullable: true
    })
};

let crud = {
    name: "unavailabilities",
    collection: "unavailabilities",
    idProperty: "_id",
    section: "base",
    labels: {
        "listTitle": "Indisponibilités",
        "singleTitle": "Indisponibilité"
    },
    emptyItem: {
        "night": false,
        "evening": false,
        "afternoon": false,
        "morning": false
    },
    completeSingle: completeSingle,
    listColumns: [{
        "name": "date",
        "label": "Date",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY")
    }, {
        "name": "user",
        "label": "Utilisateur",
        formatter: Backoffice.formatters.linkObjectId("worker", {
            idProperty: "_id",
            "label": Backoffice.formatters.fullName()
        }),
    }],
    pagination: 100,
    backPopulateList: ["user"],
    // sort: {back: {field: "date", order: "desc"}},
    loadSingle: loadSingle,
    fields: [{
        "name": "_id",
        "label": "Identifiant",
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "date",
        "label": "Date",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        editor: {
            type: "date"
        }
    }, {
        "name": "userId",
        "label": "Utilisateur",
        formatter: Backoffice.formatters.linkObjectId("worker", {
            "label": context.users.formatter()
        }),
        editor: context.users.editor()
    }, {
        "name": "night",
        "label": "Nuit",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "evening",
        "label": "Soirée",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "afternoon",
        "label": "Après-midi",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "morning",
        "label": "Matin",
        formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
        editor: {
            "type": "boolean",
            "labels": ["Non", "Oui"]
        }
    }, {
        "name": "createdAt",
        "label": "Créé le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }, {
        "name": "updatedAt",
        "label": "Mofifié le",
        "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        "readOnly": true,
        "hideOnCreate": true
    }]
};
export default crud;

function completeSingle(item) {
    if (item.night === undefined) item.night = false;
    if (item.evening === undefined) item.evening = false;
    if (item.afternoon === undefined) item.afternoon = false;
    if (item.morning === undefined) item.morning = false;
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.users.load()]);
}